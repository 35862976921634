import React, { useRef } from 'react'
import styled from 'styled-components'
import { ReactComponent as MenuIcon } from '../../wombat-assets/icons/menu.svg'
import { ReactComponent as X } from '../../wombat-assets/icons/x.svg'
import { ReactComponent as RiskIcon } from '../../wombat-assets/icons/risk.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'

import { ExternalLink } from '../../theme'

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;

  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }
`

const StyledMenu = styled.div`
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
  ${({ theme }) => theme.mediaWidth.upToSmall`margin-left: 0px`};
`

const MenuFlyout = styled.span`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: fixed;
  top: 0px;
  right: 0px;
  padding: 11px;
  width: 303px;
  height: 100vh;
  background-color: #fffcee;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 76px;
    padding: 70px 55px 0px;
    width: 100vw;
    height: 100vh - 76px;
  `};
`
const StyledX = styled(X)`
  z-index: 1;
  margin: 5px 5px 77px auto;
  minwidth: 1.5rem;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`display: none`};
`
const MenuItemWrapper = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`display: none`};
`

const MobileMenuItemWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`display: block`};
`

const MenuItem = styled(ExternalLink)`
  z-index: 1;
  margin: 9px 0px;
  min-height: 2rem;
  justify-content: center;
  opacity: 1;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.text1}
  font-size: 1.125rem;
  font-weight: 700;
  cursor: pointer;
  :hover {
    background-color: rgba(243, 214, 116, 0.5);
    opacity: 1;
  }
`

export default function Menu() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  useOnClickOutside(node, open ? toggle : undefined)

  const menuItem = [
    {
      name: 'DAPP',
      href: 'https://app.wombat.exchange',
    },
    {
      name: 'DOCUMENTATIONS',
      href: 'https://docs.wombat.exchange',
    },
    {
      name: 'GOVERNANCE FORUM',
      href: 'https://wombatexchange.discourse.group/',
    },
    {
      name: 'GITHUB',
      href: 'https://github.com/',
    },
    {
      name: 'SOCIAL',
      href: 'https://www.wombat.exchange/#community',
    },
    {
      name: 'RISK',
      href: 'https://docs.wombat.exchange/wombat-exchange/resources/risks',
    },
  ]

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledMenuButton>

      {open && (
        <MenuFlyout>
          <StyledX onClick={toggle} />
          {menuItem.map((item, i) => (
            <div key={i} onClick={toggle}>
              <MenuItemWrapper>
                <MenuItem href={item.href}>
                  {item.name === 'RISK' && <RiskIcon width={'1.5rem'} height={'1.5rem'} />}
                  {item.name}
                </MenuItem>
              </MenuItemWrapper>
              <MobileMenuItemWrapper>
                <MenuItem target="_self" href={item.href}>
                  {item.name === 'RISK' && <RiskIcon width={'1.5rem'} height={'1.5rem'} />}
                  {item.name}
                </MenuItem>
              </MobileMenuItemWrapper>
            </div>
          ))}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
