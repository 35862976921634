import React, { useMemo, useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAssetData, useAssetTransactions, useAssetChartData } from 'state/assets/hooks'
import styled from 'styled-components'
import { useColor } from 'hooks/useColor'
import { PageWrapper, ColorBackground } from 'pages/styled'
import { shortenAddress, getEtherscanLink, getDappLink } from 'utils'
import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed, AutoRow } from 'components/Row'
import { Text } from 'rebass'
import { TYPE, StyledInternalLink, ExternalLink } from 'theme'
import Loader from 'components/Loader'
import { ExternalLink as StyledExternalLink } from '../../theme/components'
import useTheme from 'hooks/useTheme'
import ShareIcon from '../../wombat-assets/icons/share.svg'
import CurrencyLogo from 'components/CurrencyLogo'
import { formatDollarAmount } from 'utils/numbers'
import { useTokenPrice } from 'hooks/useTokenPrice'
import Percent from 'components/Percent'
import LiquidityButton from '../../wombat-assets/buttons/liquidity-new.svg'
import SwapButton from '../../wombat-assets/buttons/swap-new.svg'
import Card, { LightGreyCard } from 'components/Card'
import LineChart from 'components/LineChart/alt'
import { unixToDate } from 'utils/date'
import { ToggleWrapper, ToggleElementFree } from 'components/Toggle/index'
import BarChart from 'components/BarChart/alt'
import TransactionTable from 'components/TransactionsTable'
import { TimeWindow } from 'constants/intervals'
import { MonoSpace } from 'components/shared'
import dayjs from 'dayjs'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { networkPrefix } from 'utils/networkPrefix'
import { BNBNetworkInfo } from 'constants/networks'
import { GenericImageWrapper } from 'components/Logo'
import { Colors } from 'theme/styled'
import { BaiJamjureeer } from 'components/shared'
// import { SmallOptionButton } from '../../components/Button'

const StyledDesktopRow = styled(AutoRow)`
  ${({ theme }) => theme.mediaWidth.upToSmall`display: none`};
`

const StyledMobileRow = styled(AutoRow)`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`display: flex`};
`

const StyledShareIcon = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`
const StyledButton = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  ${({ theme }) => theme.mediaWidth.upToMedium`width: 120px`};
`

const ContentLayout = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-gap: 1em;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const WhiteCard = styled(Card)`
  background-color: white;
`

export const WhiteLineChart = styled(LineChart)`
  background-color: white;
`

export const WhiteBarChart = styled(BarChart)`
  background-color: white;
`

enum ChartView {
  TVL,
  VOL,
  FEE,
}

const DEFAULT_TIME_WINDOW = TimeWindow.WEEK

export default function AssetPage({
  match: {
    params: { address },
  },
}: RouteComponentProps<{ address: string }>) {
  const [activeNetwork] = useActiveNetworkVersion()

  address = address.toLowerCase()
  // theming
  const backgroundColor = useColor(address)
  const theme = useTheme()

  // scroll on page view
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const assetData = useAssetData(address)
  const transactions = useAssetTransactions(address)
  const chartData = useAssetChartData(address)

  // format for chart component
  const formattedTvlData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.totalValueLockedUSD,
        }
      })
    } else {
      return []
    }
  }, [chartData])

  const formattedVolumeData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.volumeUSD,
        }
      })
    } else {
      return []
    }
  }, [chartData])

  const formattedFeeData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.feeUSD,
        }
      })
    } else {
      return []
    }
  }, [chartData])

  // chart labels
  const [view, setView] = useState(ChartView.TVL)
  const [latestValue, setLatestValue] = useState<number | undefined>()
  const [valueLabel, setValueLabel] = useState<string | undefined>()

  // pricing data

  const coingeckoResponse = useTokenPrice(assetData?.token)
  const isOutdated = coingeckoResponse.lastUpdate
    ? (+coingeckoResponse.lastUpdate - Date.now() / 1000) / 86400 < -1
    : true

  return (
    <PageWrapper>
      <BaiJamjureeer>
        <ColorBackground />
        {/* <ThemedBackground backgroundColor={backgroundColor} /> */}
        {assetData ? (
          !assetData.exists ? (
            <LightGreyCard style={{ textAlign: 'center' }}>
              No pool has been created with this token yet. Create one
              <StyledExternalLink style={{ marginLeft: '4px' }} href={`https://app.uniswap.org/#/add/${address}`}>
                here.
              </StyledExternalLink>
            </LightGreyCard>
          ) : (
            <AutoColumn gap="16px">
              <AutoColumn gap="24px">
                <StyledDesktopRow gap="4px">
                  <StyledInternalLink to={networkPrefix(activeNetwork)}>
                    <TYPE.subHeader fontWeight={600} color={theme.wombatPurple0}>{`OVERVIEW > `}</TYPE.subHeader>
                  </StyledInternalLink>
                  <TYPE.subHeader
                    fontWeight={600}
                    color={theme.wombatPurple0}
                  >{` ${assetData.symbol} `}</TYPE.subHeader>
                  <StyledExternalLink href={getEtherscanLink(address, 'token', activeNetwork)}>
                    <TYPE.subHeader fontWeight={600} color={theme.wombatYellow1}>{` (${shortenAddress(
                      address
                    )}) `}</TYPE.subHeader>
                  </StyledExternalLink>
                  <ExternalLink href={getEtherscanLink(address, 'token', activeNetwork)}>
                    <StyledShareIcon src={ShareIcon} size={'24px'} style={{ marginLeft: '16px' }}></StyledShareIcon>
                  </ExternalLink>
                </StyledDesktopRow>
                <StyledMobileRow justify="space-between">
                  <StyledInternalLink to={networkPrefix(activeNetwork)}>
                    <TYPE.largeHeader fontSize={'12px'} color={theme.black}>{`< BACK`}</TYPE.largeHeader>
                  </StyledInternalLink>
                  <ExternalLink href={getEtherscanLink(address, 'token', activeNetwork)}>
                    <StyledShareIcon src={ShareIcon} size={'24px'}></StyledShareIcon>
                  </ExternalLink>
                </StyledMobileRow>
                <StyledDesktopRow justify="space-between">
                  <RowFixed>
                    <CurrencyLogo address={assetData.token} style={{ borderRadius: '0px', boxShadow: 'none' }} />
                    <TYPE.label fontWeight={700} fontSize="30px" ml={'6px'} mr={'10px'}>
                      {assetData.symbol}
                    </TYPE.label>
                    <TYPE.subHeader color={theme.wombatBrown1} mr={'16px'}>
                      {assetData.name}
                    </TYPE.subHeader>
                    {activeNetwork === BNBNetworkInfo ? null : (
                      <GenericImageWrapper src={activeNetwork.imageURL} style={{ marginLeft: '8px' }} size={'26px'} />
                    )}

                    <TYPE.mediumHeader color={theme.text1} mr={'4px'}>
                      {formatDollarAmount(coingeckoResponse.currentPrice)}
                    </TYPE.mediumHeader>
                    <Percent value={coingeckoResponse.percentChange24h} fontSize={'14px'} wrap={true} />
                    <TYPE.subHeader color={theme.wombatBrown1} mr={'2px'}></TYPE.subHeader>
                  </RowFixed>
                  {activeNetwork !== BNBNetworkInfo ? null : (
                    <RowFixed>
                      <ExternalLink
                        href={getDappLink(assetData.pool, assetData.symbol, 'DEPOSIT')}
                        style={{ marginRight: '16px' }}
                      >
                        <StyledButton src={LiquidityButton} size={'160px'}></StyledButton>
                      </ExternalLink>
                      <ExternalLink href={getDappLink(assetData.pool, assetData.symbol, 'SWAP')}>
                        <StyledButton src={SwapButton} size={'160px'}></StyledButton>
                      </ExternalLink>
                    </RowFixed>
                  )}
                </StyledDesktopRow>
                <StyledDesktopRow marginTop={'-45px'}>
                  {coingeckoResponse.lastUpdate !== undefined ? (
                    <TextWrapper
                      fontWeight={400}
                      fontSize={10}
                      color={isOutdated ? 'wombatRed' : 'wombatBrown1'}
                    >{`Last Update: ${unixToDate(+coingeckoResponse.lastUpdate, 'YYYY-MM-DD HH:MM:ss')}`}</TextWrapper>
                  ) : (
                    <></>
                  )}
                </StyledDesktopRow>
                <StyledMobileRow justify="space-between">
                  <RowFixed>
                    <CurrencyLogo
                      address={assetData.token}
                      style={{ marginRight: '8px', borderRadius: '0px', boxShadow: 'none' }}
                    />
                    <AutoColumn>
                      <TYPE.label fontWeight={700} fontSize="18px">
                        {assetData.symbol}
                      </TYPE.label>
                      <TYPE.subHeader color={theme.wombatBrown1}>{assetData.name}</TYPE.subHeader>
                    </AutoColumn>
                  </RowFixed>
                  <RowFixed>
                    <TYPE.mediumHeader color={theme.text1} mr={'4px'}>
                      {formatDollarAmount(coingeckoResponse.currentPrice)}
                    </TYPE.mediumHeader>
                    <Percent value={coingeckoResponse.percentChange24h} fontSize={'14px'} wrap={true} />
                  </RowFixed>
                </StyledMobileRow>
              </AutoColumn>
              <ContentLayout>
                <WhiteCard>
                  <AutoColumn gap="lg">
                    <AutoColumn gap="8px">
                      <TYPE.main fontWeight={400}>TVL</TYPE.main>
                      <TYPE.label fontSize="20px">{formatDollarAmount(assetData.tvlUSD)}</TYPE.label>
                      <Percent value={assetData.tvlUSDChange} />
                    </AutoColumn>
                    <AutoColumn gap="8px">
                      <TYPE.main fontWeight={400}>Volume [24H]</TYPE.main>
                      <TYPE.label fontSize="20px">{formatDollarAmount(assetData.volume24HUSD)}</TYPE.label>
                      <Percent value={assetData.volumeUSDChange} />
                    </AutoColumn>
                    <AutoColumn gap="8px">
                      <TYPE.main fontWeight={400}>Fee [24H]</TYPE.main>
                      <TYPE.label fontSize="20px">{formatDollarAmount(assetData.fee24HUSD)}</TYPE.label>
                      <Percent value={assetData.feeUSDChange} />
                    </AutoColumn>
                  </AutoColumn>
                </WhiteCard>
                <WhiteCard>
                  <RowBetween align="flex-start">
                    <AutoColumn>
                      <RowFixed>
                        <TYPE.label fontSize="24px" height="30px">
                          <MonoSpace>
                            {latestValue
                              ? formatDollarAmount(latestValue, 2)
                              : view === ChartView.VOL
                              ? formatDollarAmount(formattedVolumeData[formattedVolumeData.length - 1]?.value)
                              : view === ChartView.TVL
                              ? formatDollarAmount(formattedTvlData[formattedTvlData.length - 1]?.value)
                              : formatDollarAmount(formattedFeeData[formattedFeeData.length - 1]?.value)}
                          </MonoSpace>
                        </TYPE.label>
                      </RowFixed>
                      <TYPE.main height="20px" fontSize="12px">
                        {valueLabel ? (
                          <MonoSpace>{valueLabel} (UTC)</MonoSpace>
                        ) : (
                          <MonoSpace>{dayjs.utc().format('MMM D, YYYY')}</MonoSpace>
                        )}
                      </TYPE.main>
                    </AutoColumn>
                    <ToggleWrapper width="180px">
                      <ToggleElementFree
                        isActive={view === ChartView.TVL}
                        fontSize="12px"
                        onClick={() => (view === ChartView.TVL ? setView(ChartView.VOL) : setView(ChartView.TVL))}
                      >
                        TVL
                      </ToggleElementFree>
                      <ToggleElementFree
                        isActive={view === ChartView.VOL}
                        fontSize="12px"
                        onClick={() => (view === ChartView.VOL ? setView(ChartView.FEE) : setView(ChartView.VOL))}
                      >
                        Volume
                      </ToggleElementFree>
                      <ToggleElementFree
                        isActive={view === ChartView.FEE}
                        fontSize="12px"
                        onClick={() => setView(ChartView.FEE)}
                      >
                        Fee
                      </ToggleElementFree>
                    </ToggleWrapper>
                  </RowBetween>
                  {view === ChartView.TVL ? (
                    <WhiteLineChart
                      data={formattedTvlData}
                      color={backgroundColor}
                      minHeight={240}
                      value={latestValue}
                      label={valueLabel}
                      setValue={setLatestValue}
                      setLabel={setValueLabel}
                    />
                  ) : view === ChartView.VOL ? (
                    <WhiteBarChart
                      data={formattedVolumeData}
                      color={backgroundColor}
                      minHeight={240}
                      value={latestValue}
                      label={valueLabel}
                      setValue={setLatestValue}
                      setLabel={setValueLabel}
                    />
                  ) : view === ChartView.FEE ? (
                    <WhiteBarChart
                      data={formattedFeeData}
                      color={backgroundColor}
                      minHeight={240}
                      value={latestValue}
                      label={valueLabel}
                      setValue={setLatestValue}
                      setLabel={setValueLabel}
                    />
                  ) : null}
                  {/* <RowBetween width="100%">
                  <div> </div>
                  <AutoRow gap="4px" width="fit-content">
                    <SmallOptionButton
                      active={timeWindow === TimeWindow.DAY}
                      onClick={() => setTimeWindow(TimeWindow.DAY)}
                    >
                      24H
                    </SmallOptionButton>
                    <SmallOptionButton
                      active={timeWindow === TimeWindow.WEEK}
                      onClick={() => setTimeWindow(TimeWindow.WEEK)}
                    >
                      1W
                    </SmallOptionButton>
                    <SmallOptionButton
                      active={timeWindow === TimeWindow.MONTH}
                      onClick={() => setTimeWindow(TimeWindow.MONTH)}
                    >
                      1M
                    </SmallOptionButton>
                    <SmallOptionButton
                      active={timeWindow === TimeWindow.DAY}
                      onClick={() => setTimeWindow(TimeWindow.DAY)}
                    >
                      All
                    </SmallOptionButton>
                  </AutoRow>
                </RowBetween> */}
                </WhiteCard>
              </ContentLayout>
              <TYPE.main>Transactions</TYPE.main>
              {transactions ? <TransactionTable transactions={transactions} color={backgroundColor} /> : <></>}
            </AutoColumn>
          )
        ) : (
          <Loader />
        )}
      </BaiJamjureeer>
    </PageWrapper>
  )
}
