import React, { Dispatch, SetStateAction, ReactNode } from 'react'
import { ResponsiveContainer, XAxis, Tooltip, AreaChart, Area } from 'recharts'
import styled from 'styled-components'
import Card from 'components/Card'
import { RowBetween } from 'components/Row'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useTheme from 'hooks/useTheme'
dayjs.extend(utc)

const Wrapper = styled(Card)`
  width: 100%;
  display: flex;
  background-color: white;
  opacity: 0.95;
  flex-direction: column;
  > * {
    font-size: 1rem;
  }
`

export type LineChartProps = {
  data: any[]
  color?: string | undefined
  height?: number | undefined
  minHeight?: number
  setValue?: Dispatch<SetStateAction<number | undefined>> // used for value on hover
  setLabel?: Dispatch<SetStateAction<string | undefined>> // used for label of valye
  value?: number
  label?: string
  topLeft?: ReactNode | undefined
  topRight?: ReactNode | undefined
  bottomLeft?: ReactNode | undefined
  bottomRight?: ReactNode | undefined
} & React.HTMLAttributes<HTMLDivElement>

const Chart = ({
  data,
  value,
  label,
  setValue,
  setLabel,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  height = 180,
  ...rest
}: LineChartProps) => {
  const theme = useTheme()
  const parsedValue = value

  return (
    <Wrapper {...rest}>
      <RowBetween>
        {topLeft ?? null}
        {topRight ?? null}
      </RowBetween>
      <div style={{ position: 'relative', width: '100%', height: '150px' }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          <ResponsiveContainer width="100%" height={height}>
            <AreaChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                bottom: 5,
              }}
              onMouseLeave={() => {
                setLabel && setLabel(undefined)
                setValue && setValue(undefined)
              }}
            >
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1" gradientTransform="rotate(311)">
                  <stop offset="23.41%" stopColor="#B672D6" stopOpacity={0.1} />
                  <stop offset="44.46%" stopColor="#FFDD52" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="time"
                axisLine={false}
                tickLine={false}
                tickFormatter={(time) => dayjs(time).format('DD')}
                minTickGap={10}
              />
              <Tooltip
                cursor={{ stroke: theme.bg2 }}
                contentStyle={{ display: 'none' }}
                formatter={(value: number, name: string, props: { payload: { time: string; value: number } }) => {
                  if (setValue && parsedValue !== props.payload.value) {
                    setValue(props.payload.value)
                  }
                  const formattedTime = dayjs(props.payload.time).format('MMM D, YYYY')
                  if (setLabel && label !== formattedTime) setLabel(formattedTime)
                }}
              />
              <Area dataKey="value" type="monotone" stroke="#B672D6" fill="url(#gradient)" strokeWidth={2} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      <RowBetween>
        {bottomLeft ?? null}
        {bottomRight ?? null}
      </RowBetween>
    </Wrapper>
  )
}

export default Chart
