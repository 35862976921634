import { ChartDayData } from '../../types/index'
import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import gql from 'graphql-tag'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { GLOBAL_TOKENS, parseTokensDecimals, GlobalTokensResponse } from './overview'
import { ClientType, useActiveNetworkVersion, useDataClient } from 'state/application/hooks'
import { SupportedNetwork } from 'constants/networks'
import { BNB_TVL_VOLUME_DATA_BEFORE_19498_INCLUSIVE } from 'constants/data'

// format dayjs with the libraries that we need
dayjs.extend(utc)
dayjs.extend(weekOfYear)
const ONE_DAY_UNIX = 24 * 60 * 60

const GLOBAL_CHART = gql`
  query assetDayDatas($startTime: Int!, $skip: Int!) {
    assetDayDatas(
      first: 1000
      skip: $skip
      where: { dayID_gt: $startTime, asset_not: "0x3f56e0c36d275367b8c502090edf38289b3dea0d" }
      orderBy: dayID
      orderDirection: asc
      subgraphError: allow
    ) {
      dayID
      dayVolume: dailyTradeVolumeUSD
      dailyLiability: dailyLiabilityUSD
      asset {
        underlyingToken {
          id
        }
        poolAddress
      }
    }
  }
`

interface Data {
  dayID: string
  dailyLiability: string
  dayVolume: string
  asset: {
    underlyingToken: {
      id: string
    }
    poolAddress: string
  }
}

interface ChartResults {
  assetDayDatas: Data[]
}

async function fetchChartData(client: ApolloClient<NormalizedCacheObject>, networkId: SupportedNetwork) {
  let data: Data[] = []
  let error = false
  let skip = 0
  let allFound = false
  const startTimestamp = 1647820800 // 21 March 2022 00:00:00
  const endTimestamp = dayjs.utc().unix()
  const ankrExploitTimestamp = parseInt((1669941795 / ONE_DAY_UNIX).toFixed(0)) // Friday, December 2, 2022 12:43:15 AM
  try {
    while (!allFound) {
      const { data: chartResData, error, loading } = await client.query<ChartResults>({
        query: GLOBAL_CHART,
        variables: {
          startTime: networkId === SupportedNetwork.BNB ? 19498 : parseInt((startTimestamp / ONE_DAY_UNIX).toFixed(0)),
          skip,
        },
        fetchPolicy: 'no-cache',
      })
      if (!loading) {
        skip += 1000
        if (chartResData.assetDayDatas.length < 1000 || error) {
          allFound = true
        }
        if (chartResData) {
          data = data.concat(chartResData.assetDayDatas)
        }
      }
    }
  } catch {
    error = true
  }
  if (data) {
    let allTokenDecimals: { [key: string]: number } | undefined
    const { loading: loadingTokens, error: tokensError, data: tokensData } = await client.query<GlobalTokensResponse>({
      query: GLOBAL_TOKENS(),
      fetchPolicy: 'no-cache',
    })
    let allTokenAddrs: Array<string> = []
    if (!loadingTokens && !tokensError) {
      const parsedTokens = tokensData?.assets
      allTokenDecimals = parseTokensDecimals(parsedTokens)
      allTokenAddrs = allTokenDecimals ? Object.keys(allTokenDecimals) : []
    }
    const dayTokenTvls: { [key: number]: { [key: string]: number } } = {}
    let formattedExisting = data.reduce((accum: { [date: number]: ChartDayData }, dayData) => {
      const dayID = parseInt(dayData.dayID)
      const tokenAddr = dayData.asset.underlyingToken.id
      const dayTvl =
        dayData.asset.poolAddress.toLowerCase() == '0x0029b7e8e9ed8001c868aa09c74a1ac6269d4183' &&
        Number(dayData.dayID) >= ankrExploitTimestamp
          ? 0
          : +dayData.dailyLiability
      const dayVol =
        dayData.asset.poolAddress.toLowerCase() == '0x0029b7e8e9ed8001c868aa09c74a1ac6269d4183' &&
        Number(dayData.dayID) >= ankrExploitTimestamp
          ? 0
          : parseFloat(dayData.dayVolume) / 2

      if (dayID in accum) {
        dayTokenTvls[dayID][tokenAddr] = dayTvl
        accum[dayID].tvlUSD += dayTvl
        accum[dayID].volumeUSD += dayVol
      } else {
        dayTokenTvls[dayID] = {}
        dayTokenTvls[dayID][tokenAddr] = dayTvl
        accum[dayID] = {
          date: parseInt((dayID * ONE_DAY_UNIX).toFixed(0)),
          tvlUSD: dayTvl,
          volumeUSD: dayVol,
        }
      }
      return accum
    }, {})

    if (networkId === SupportedNetwork.BNB) {
      formattedExisting = { ...BNB_TVL_VOLUME_DATA_BEFORE_19498_INCLUSIVE, ...formattedExisting }
    }

    return {
      data: Object.values(formattedExisting).slice(0, Object.values(formattedExisting).length - 1),
      error: false,
    }
  } else {
    return {
      data: undefined,
      error,
    }
  }
}

/**
 * Fetch historic chart data
 */
export function useFetchGlobalChartData(): {
  error: boolean
  data: ChartDayData[] | undefined
} {
  const [data, setData] = useState<{ [network: string]: ChartDayData[] | undefined }>()
  const [error, setError] = useState(false)
  const dataClient = useDataClient()[ClientType.MAIN]

  const [activeNetwork] = useActiveNetworkVersion()
  const indexedData = data?.[activeNetwork.id]
  useEffect(() => {
    async function fetch() {
      const { data, error } = await fetchChartData(dataClient, activeNetwork.id)
      if (data && !error) {
        setData({
          [activeNetwork.id]: data,
        })
      } else if (error) {
        setError(true)
      }
    }
    if (!error && !indexedData) {
      fetch()
    }
  }, [data, error, dataClient, activeNetwork.id, indexedData])

  return { error, data: indexedData }
}
