export const BNB_TVL_VOLUME_DATA_BEFORE_19498_INCLUSIVE = {
  19100: {
    date: 1650240000,
    tvlUSD: 0.13901302091,
    volumeUSD: 0.00010003676801064148,
  },
  19101: {
    date: 1650326400,
    tvlUSD: 0.13901302091,
    volumeUSD: 0,
  },
  19102: {
    date: 1650412800,
    tvlUSD: 10.051069923396462,
    volumeUSD: 0,
  },
  19103: {
    date: 1650499200,
    tvlUSD: 10.076093031896422,
    volumeUSD: 0.001199886318197044,
  },
  19104: {
    date: 1650585600,
    tvlUSD: 10.076093031896422,
    volumeUSD: 0,
  },
  19105: {
    date: 1650672000,
    tvlUSD: 10.076093031896422,
    volumeUSD: 0,
  },
  19106: {
    date: 1650758400,
    tvlUSD: 0.11699568177637634,
    volumeUSD: 0.0020002485969507254,
  },
  19107: {
    date: 1650844800,
    tvlUSD: 20001947.66718881,
    volumeUSD: 2747400.9479331383,
  },
  19108: {
    date: 1650931200,
    tvlUSD: 20004644.366171487,
    volumeUSD: 1822566.145759728,
  },
  19109: {
    date: 1651017600,
    tvlUSD: 20000126.258020137,
    volumeUSD: 872582.0259436825,
  },
  19110: {
    date: 1651104000,
    tvlUSD: 20001417.570501603,
    volumeUSD: 358422.6530319316,
  },
  19111: {
    date: 1651190400,
    tvlUSD: 20005559.80586888,
    volumeUSD: 621688.9140273217,
  },
  19112: {
    date: 1651276800,
    tvlUSD: 20022858.886434574,
    volumeUSD: 805661.5077451481,
  },
  19113: {
    date: 1651363200,
    tvlUSD: 20006401.19140149,
    volumeUSD: 539280.8153860031,
  },
  19114: {
    date: 1651449600,
    tvlUSD: 20001702.25285834,
    volumeUSD: 936388.673918398,
  },
  19115: {
    date: 1651536000,
    tvlUSD: 20005363.20292112,
    volumeUSD: 296947.88752157934,
  },
  19116: {
    date: 1651622400,
    tvlUSD: 20003334.544934005,
    volumeUSD: 886864.7220548482,
  },
  19117: {
    date: 1651708800,
    tvlUSD: 20001292.544940583,
    volumeUSD: 2459720.198395033,
  },
  19118: {
    date: 1651795200,
    tvlUSD: 20005137.76647439,
    volumeUSD: 2547343.633704582,
  },
  19119: {
    date: 1651881600,
    tvlUSD: 20006021.272715397,
    volumeUSD: 1471966.7021048795,
  },
  19120: {
    date: 1651968000,
    tvlUSD: 20004347.447072875,
    volumeUSD: 816333.2300675851,
  },
  19121: {
    date: 1652054400,
    tvlUSD: 19999989.652003497,
    volumeUSD: 1986131.7141102673,
  },
  19122: {
    date: 1652140800,
    tvlUSD: 19999135.0527685,
    volumeUSD: 867314.4037897137,
  },
  19123: {
    date: 1652227200,
    tvlUSD: 19080950.622671094,
    volumeUSD: 14033198.012130786,
  },
  19124: {
    date: 1652313600,
    tvlUSD: 19284697.436468482,
    volumeUSD: 34904818.33557983,
  },
  19125: {
    date: 1652400000,
    tvlUSD: 18714071.151379712,
    volumeUSD: 6181203.622855363,
  },
  19126: {
    date: 1652486400,
    tvlUSD: 18758172.094629757,
    volumeUSD: 2420940.1859520236,
  },
  19127: {
    date: 1652572800,
    tvlUSD: 18979883.88498202,
    volumeUSD: 1514612.2153480912,
  },
  19128: {
    date: 1652659200,
    tvlUSD: 18876463.40703493,
    volumeUSD: 1279738.7694400803,
  },
  19129: {
    date: 1652745600,
    tvlUSD: 19229579.57384295,
    volumeUSD: 663238.1829212689,
  },
  19130: {
    date: 1652832000,
    tvlUSD: 20002489.22037411,
    volumeUSD: 2041011.7200386645,
  },
  19131: {
    date: 1652918400,
    tvlUSD: 33308568.971592158,
    volumeUSD: 3026433.1928394684,
  },
  19132: {
    date: 1653004800,
    tvlUSD: 39994217.47098018,
    volumeUSD: 2634507.425207423,
  },
  19133: {
    date: 1653091200,
    tvlUSD: 40018270.27155935,
    volumeUSD: 1630190.2028548871,
  },
  19134: {
    date: 1653177600,
    tvlUSD: 39746837.21240029,
    volumeUSD: 1221821.9064621008,
  },
  19135: {
    date: 1653264000,
    tvlUSD: 39983173.1181575,
    volumeUSD: 1861026.8108715445,
  },
  19136: {
    date: 1653350400,
    tvlUSD: 40006126.86325744,
    volumeUSD: 2423023.5012478735,
  },
  19137: {
    date: 1653436800,
    tvlUSD: 39975466.96678398,
    volumeUSD: 826962.2162829869,
  },
  19138: {
    date: 1653523200,
    tvlUSD: 40010785.671221085,
    volumeUSD: 2081282.0488983388,
  },
  19139: {
    date: 1653609600,
    tvlUSD: 39990966.43083942,
    volumeUSD: 1128432.981661197,
  },
  19140: {
    date: 1653696000,
    tvlUSD: 39998622.77346402,
    volumeUSD: 904568.5509452291,
  },
  19141: {
    date: 1653782400,
    tvlUSD: 39891250.83587371,
    volumeUSD: 887874.7846625373,
  },
  19142: {
    date: 1653868800,
    tvlUSD: 40006331.356818855,
    volumeUSD: 2338434.620450477,
  },
  19143: {
    date: 1653955200,
    tvlUSD: 40003512.350996315,
    volumeUSD: 596464.7155865284,
  },
  19144: {
    date: 1654041600,
    tvlUSD: 40014648.42702339,
    volumeUSD: 1091136.9351577484,
  },
  19145: {
    date: 1654128000,
    tvlUSD: 40015616.80613229,
    volumeUSD: 463015.5398495545,
  },
  19146: {
    date: 1654214400,
    tvlUSD: 40014473.36000923,
    volumeUSD: 612256.02697151,
  },
  19147: {
    date: 1654300800,
    tvlUSD: 40008464.04177528,
    volumeUSD: 596449.5226764134,
  },
  19148: {
    date: 1654387200,
    tvlUSD: 40016303.65993829,
    volumeUSD: 468442.4554956154,
  },
  19149: {
    date: 1654473600,
    tvlUSD: 40012643.06586395,
    volumeUSD: 3665525.077197069,
  },
  19150: {
    date: 1654560000,
    tvlUSD: 40012337.6659445,
    volumeUSD: 2955602.653335884,
  },
  19151: {
    date: 1654646400,
    tvlUSD: 40009253.89823501,
    volumeUSD: 760043.5059851895,
  },
  19152: {
    date: 1654732800,
    tvlUSD: 40005882.22314177,
    volumeUSD: 539213.6249447193,
  },
  19153: {
    date: 1654819200,
    tvlUSD: 40012654.762198485,
    volumeUSD: 677000.540087887,
  },
  19154: {
    date: 1654905600,
    tvlUSD: 38150921.07286988,
    volumeUSD: 695558.9191209717,
  },
  19155: {
    date: 1654992000,
    tvlUSD: 38843897.994190246,
    volumeUSD: 1412081.9359729292,
  },
  19156: {
    date: 1655078400,
    tvlUSD: 35512796.33418812,
    volumeUSD: 11222302.171034612,
  },
  19157: {
    date: 1655164800,
    tvlUSD: 29733864.086499654,
    volumeUSD: 2026531.2369319303,
  },
  19158: {
    date: 1655251200,
    tvlUSD: 17148889.951000225,
    volumeUSD: 3557792.516515791,
  },
  19159: {
    date: 1655337600,
    tvlUSD: 16685271.905024994,
    volumeUSD: 1600082.714453172,
  },
  19160: {
    date: 1655424000,
    tvlUSD: 16626509.154091327,
    volumeUSD: 590021.7785268428,
  },
  19161: {
    date: 1655510400,
    tvlUSD: 16368624.8936637,
    volumeUSD: 1127052.0306320603,
  },
  19162: {
    date: 1655596800,
    tvlUSD: 16765311.991368858,
    volumeUSD: 1875231.5033770765,
  },
  19163: {
    date: 1655683200,
    tvlUSD: 16495977.722032953,
    volumeUSD: 674595.3159661756,
  },
  19164: {
    date: 1655769600,
    tvlUSD: 16885865.14616306,
    volumeUSD: 947758.2586734213,
  },
  19165: {
    date: 1655856000,
    tvlUSD: 16914717.081175637,
    volumeUSD: 239397.45692977036,
  },
  19166: {
    date: 1655942400,
    tvlUSD: 16956864.742738537,
    volumeUSD: 676437.3684766229,
  },
  19167: {
    date: 1656028800,
    tvlUSD: 16975598.5407051,
    volumeUSD: 674145.4738581129,
  },
  19168: {
    date: 1656115200,
    tvlUSD: 16991830.48603732,
    volumeUSD: 331297.18413686805,
  },
  19169: {
    date: 1656201600,
    tvlUSD: 17226245.447970487,
    volumeUSD: 77986.19669286601,
  },
  19170: {
    date: 1656288000,
    tvlUSD: 17236457.33999112,
    volumeUSD: 378752.5996818504,
  },
  19171: {
    date: 1656374400,
    tvlUSD: 17266711.390398398,
    volumeUSD: 419257.1857503829,
  },
  19172: {
    date: 1656460800,
    tvlUSD: 16989707.99782204,
    volumeUSD: 276228.76448551705,
  },
  19173: {
    date: 1656547200,
    tvlUSD: 15964280.081791107,
    volumeUSD: 3919844.7969755796,
  },
  19174: {
    date: 1656633600,
    tvlUSD: 15941460.447883414,
    volumeUSD: 570296.1613548155,
  },
  19175: {
    date: 1656720000,
    tvlUSD: 15887105.31742666,
    volumeUSD: 430571.8331234619,
  },
  19176: {
    date: 1656806400,
    tvlUSD: 15657537.207695678,
    volumeUSD: 360189.3360156035,
  },
  19177: {
    date: 1656892800,
    tvlUSD: 15667364.040451055,
    volumeUSD: 146733.14203935693,
  },
  19178: {
    date: 1656979200,
    tvlUSD: 15593610.383962713,
    volumeUSD: 214996.54054875934,
  },
  19179: {
    date: 1657065600,
    tvlUSD: 15841063.630816873,
    volumeUSD: 1393440.6913669682,
  },
  19180: {
    date: 1657152000,
    tvlUSD: 15948481.82256027,
    volumeUSD: 208921.2425370691,
  },
  19181: {
    date: 1657238400,
    tvlUSD: 16747631.448976401,
    volumeUSD: 330610.9350629903,
  },
  19182: {
    date: 1657324800,
    tvlUSD: 16738351.340851866,
    volumeUSD: 218138.6462778449,
  },
  19183: {
    date: 1657411200,
    tvlUSD: 16416817.30309347,
    volumeUSD: 391125.3218318508,
  },
  19184: {
    date: 1657497600,
    tvlUSD: 16335873.615794577,
    volumeUSD: 428487.4876765205,
  },
  19185: {
    date: 1657584000,
    tvlUSD: 13202342.764505953,
    volumeUSD: 914412.7213082926,
  },
  19186: {
    date: 1657670400,
    tvlUSD: 12621846.886613848,
    volumeUSD: 566654.6083778532,
  },
  19187: {
    date: 1657756800,
    tvlUSD: 12602075.642486872,
    volumeUSD: 236277.55006822295,
  },
  19188: {
    date: 1657843200,
    tvlUSD: 12376649.936444491,
    volumeUSD: 280618.66168703145,
  },
  19189: {
    date: 1657929600,
    tvlUSD: 12351657.160322163,
    volumeUSD: 190682.11826212332,
  },
  19190: {
    date: 1658016000,
    tvlUSD: 12283010.774197139,
    volumeUSD: 51007.46755519515,
  },
  19191: {
    date: 1658102400,
    tvlUSD: 12139414.38201718,
    volumeUSD: 251711.99895728455,
  },
  19192: {
    date: 1658188800,
    tvlUSD: 12167746.77011614,
    volumeUSD: 333215.3782734633,
  },
  19193: {
    date: 1658275200,
    tvlUSD: 12414408.819871502,
    volumeUSD: 52639.81296344774,
  },
  19194: {
    date: 1658361600,
    tvlUSD: 12416787.168287292,
    volumeUSD: 67376.49447797655,
  },
  19195: {
    date: 1658448000,
    tvlUSD: 12365916.968118701,
    volumeUSD: 53343.8616243554,
  },
  19196: {
    date: 1658534400,
    tvlUSD: 12050672.196033083,
    volumeUSD: 46358.921619736444,
  },
  19197: {
    date: 1658620800,
    tvlUSD: 12089315.950674107,
    volumeUSD: 98509.10117780864,
  },
  19198: {
    date: 1658707200,
    tvlUSD: 12067103.963183483,
    volumeUSD: 87570.7638105483,
  },
  19199: {
    date: 1658793600,
    tvlUSD: 11610938.106293518,
    volumeUSD: 85070.6407801382,
  },
  19200: {
    date: 1658880000,
    tvlUSD: 11596349.018999588,
    volumeUSD: 138938.92317682412,
  },
  19201: {
    date: 1658966400,
    tvlUSD: 11682176.389545785,
    volumeUSD: 127849.25942664033,
  },
  19202: {
    date: 1659052800,
    tvlUSD: 11482100.998800347,
    volumeUSD: 303492.1888466141,
  },
  19203: {
    date: 1659139200,
    tvlUSD: 11479957.67029794,
    volumeUSD: 84692.73333363699,
  },
  19204: {
    date: 1659225600,
    tvlUSD: 11456245.896653172,
    volumeUSD: 9397.54932904519,
  },
  19205: {
    date: 1659312000,
    tvlUSD: 11311765.710031392,
    volumeUSD: 98277.35262838776,
  },
  19206: {
    date: 1659398400,
    tvlUSD: 11560784.434280433,
    volumeUSD: 111879.43107086694,
  },
  19207: {
    date: 1659484800,
    tvlUSD: 11274646.390483461,
    volumeUSD: 91398.27090841183,
  },
  19208: {
    date: 1659571200,
    tvlUSD: 9697245.570343742,
    volumeUSD: 26645.19288435238,
  },
  19209: {
    date: 1659657600,
    tvlUSD: 11283757.9701727,
    volumeUSD: 300442.02989334596,
  },
  19210: {
    date: 1659744000,
    tvlUSD: 11267571.027428411,
    volumeUSD: 161081.37496513707,
  },
  19211: {
    date: 1659830400,
    tvlUSD: 11253179.304393027,
    volumeUSD: 23512.94628658301,
  },
  19212: {
    date: 1659916800,
    tvlUSD: 11267471.028845135,
    volumeUSD: 38084.57633171295,
  },
  19213: {
    date: 1660003200,
    tvlUSD: 11755237.942121647,
    volumeUSD: 564491.1019290633,
  },
  19214: {
    date: 1660089600,
    tvlUSD: 12818202.31980791,
    volumeUSD: 254650.9251717689,
  },
  19215: {
    date: 1660176000,
    tvlUSD: 12889825.72820721,
    volumeUSD: 374909.24806062266,
  },
  19216: {
    date: 1660262400,
    tvlUSD: 12873697.546535902,
    volumeUSD: 300523.57032071584,
  },
  19217: {
    date: 1660348800,
    tvlUSD: 12717605.310400236,
    volumeUSD: 224732.20844081693,
  },
  19218: {
    date: 1660435200,
    tvlUSD: 12807795.662455529,
    volumeUSD: 33662.10951695821,
  },
  19219: {
    date: 1660521600,
    tvlUSD: 13690750.561130214,
    volumeUSD: 188143.4679539147,
  },
  19220: {
    date: 1660608000,
    tvlUSD: 14552767.008952871,
    volumeUSD: 297428.02703022445,
  },
  19221: {
    date: 1660694400,
    tvlUSD: 14586981.315313185,
    volumeUSD: 303568.9796098442,
  },
  19222: {
    date: 1660780800,
    tvlUSD: 15160151.2323541,
    volumeUSD: 146413.9247800609,
  },
  19223: {
    date: 1660867200,
    tvlUSD: 15705202.226698771,
    volumeUSD: 160059.23664211424,
  },
  19224: {
    date: 1660953600,
    tvlUSD: 16359783.016316477,
    volumeUSD: 186904.02936084638,
  },
  19225: {
    date: 1661040000,
    tvlUSD: 16475930.401643483,
    volumeUSD: 55648.11468790173,
  },
  19226: {
    date: 1661126400,
    tvlUSD: 16846006.90107068,
    volumeUSD: 788608.6696972925,
  },
  19227: {
    date: 1661212800,
    tvlUSD: 16930703.25505325,
    volumeUSD: 143069.1502623045,
  },
  19228: {
    date: 1661299200,
    tvlUSD: 17194949.29875558,
    volumeUSD: 95087.39549368406,
  },
  19229: {
    date: 1661385600,
    tvlUSD: 17871878.131449595,
    volumeUSD: 937043.063112101,
  },
  19230: {
    date: 1661472000,
    tvlUSD: 17606577.87071976,
    volumeUSD: 248610.57403089677,
  },
  19231: {
    date: 1661558400,
    tvlUSD: 17992089.680667963,
    volumeUSD: 182342.0506937561,
  },
  19232: {
    date: 1661644800,
    tvlUSD: 18234052.274435796,
    volumeUSD: 64942.292107449284,
  },
  19233: {
    date: 1661731200,
    tvlUSD: 19319237.744268097,
    volumeUSD: 104553.41711542006,
  },
  19234: {
    date: 1661817600,
    tvlUSD: 19348642.65383271,
    volumeUSD: 1966669.743116175,
  },
  19235: {
    date: 1661904000,
    tvlUSD: 20326525.280723035,
    volumeUSD: 564234.6717942097,
  },
  19236: {
    date: 1661990400,
    tvlUSD: 22970659.78380072,
    volumeUSD: 942295.3888672972,
  },
  19237: {
    date: 1662076800,
    tvlUSD: 25138928.963363975,
    volumeUSD: 321426.808072647,
  },
  19238: {
    date: 1662163200,
    tvlUSD: 28300240.181904837,
    volumeUSD: 104021.94384648962,
  },
  19239: {
    date: 1662249600,
    tvlUSD: 33728938.231481016,
    volumeUSD: 1962862.3210300824,
  },
  19240: {
    date: 1662336000,
    tvlUSD: 217695992.20261645,
    volumeUSD: 9773692.112275027,
  },
  19241: {
    date: 1662422400,
    tvlUSD: 184759611.78505725,
    volumeUSD: 3174350.684704362,
  },
  19242: {
    date: 1662508800,
    tvlUSD: 186295554.5540343,
    volumeUSD: 1711568.2239233137,
  },
  19243: {
    date: 1662595200,
    tvlUSD: 187084888.86023477,
    volumeUSD: 8683056.806349745,
  },
  19244: {
    date: 1662681600,
    tvlUSD: 185310986.4832397,
    volumeUSD: 3040796.661153616,
  },
  19245: {
    date: 1662768000,
    tvlUSD: 180953400.35386372,
    volumeUSD: 2179029.1611424494,
  },
  19246: {
    date: 1662854400,
    tvlUSD: 172166217.97021887,
    volumeUSD: 460112.94263551524,
  },
  19247: {
    date: 1662940800,
    tvlUSD: 162290336.7795025,
    volumeUSD: 808335.4916355287,
  },
  19248: {
    date: 1663027200,
    tvlUSD: 152507431.52961424,
    volumeUSD: 4047878.196725345,
  },
  19249: {
    date: 1663113600,
    tvlUSD: 122344507.43232054,
    volumeUSD: 3689583.7328080637,
  },
  19250: {
    date: 1663200000,
    tvlUSD: 137498192.08668408,
    volumeUSD: 2758394.32148635,
  },
  19251: {
    date: 1663286400,
    tvlUSD: 129733497.50613111,
    volumeUSD: 1086552.402444375,
  },
  19252: {
    date: 1663372800,
    tvlUSD: 122940128.87832487,
    volumeUSD: 648106.2912035324,
  },
  19253: {
    date: 1663459200,
    tvlUSD: 118488985.85123573,
    volumeUSD: 986185.050453815,
  },
  19254: {
    date: 1663545600,
    tvlUSD: 113950297.91266698,
    volumeUSD: 2048387.2039844296,
  },
  19255: {
    date: 1663632000,
    tvlUSD: 116552947.25221518,
    volumeUSD: 740313.2959139439,
  },
  19256: {
    date: 1663718400,
    tvlUSD: 119155684.22084145,
    volumeUSD: 2684658.1328884866,
  },
  19257: {
    date: 1663804800,
    tvlUSD: 113883214.5289115,
    volumeUSD: 2476240.1270780065,
  },
  19258: {
    date: 1663891200,
    tvlUSD: 108877942.83175963,
    volumeUSD: 2010554.298653951,
  },
  19259: {
    date: 1663977600,
    tvlUSD: 108503794.71207659,
    volumeUSD: 1245100.6170711932,
  },
  19260: {
    date: 1664064000,
    tvlUSD: 110297823.88562182,
    volumeUSD: 794726.8180940967,
  },
  19261: {
    date: 1664150400,
    tvlUSD: 106340751.5686499,
    volumeUSD: 1248819.5340949781,
  },
  19262: {
    date: 1664236800,
    tvlUSD: 105796322.21103013,
    volumeUSD: 1904880.8750740443,
  },
  19263: {
    date: 1664323200,
    tvlUSD: 105371543.17972724,
    volumeUSD: 1243845.3587129514,
  },
  19264: {
    date: 1664409600,
    tvlUSD: 98214810.93311547,
    volumeUSD: 2293916.5935894786,
  },
  19265: {
    date: 1664496000,
    tvlUSD: 98673481.81458083,
    volumeUSD: 4396463.073483521,
  },
  19266: {
    date: 1664582400,
    tvlUSD: 99345716.23448312,
    volumeUSD: 9995362.858350305,
  },
  19267: {
    date: 1664668800,
    tvlUSD: 98742143.35788637,
    volumeUSD: 3159673.6830207226,
  },
  19268: {
    date: 1664755200,
    tvlUSD: 94911960.34693232,
    volumeUSD: 3080559.9161175247,
  },
  19269: {
    date: 1664841600,
    tvlUSD: 95790991.17460063,
    volumeUSD: 2993775.8346080286,
  },
  19270: {
    date: 1664928000,
    tvlUSD: 98533976.52645676,
    volumeUSD: 2233524.9061954515,
  },
  19271: {
    date: 1665014400,
    tvlUSD: 100818323.427408,
    volumeUSD: 21324779.112258255,
  },
  19272: {
    date: 1665100800,
    tvlUSD: 102260441.67573175,
    volumeUSD: 12469903.5106355,
  },
  19273: {
    date: 1665187200,
    tvlUSD: 104975872.57920234,
    volumeUSD: 4585755.150330637,
  },
  19274: {
    date: 1665273600,
    tvlUSD: 110155565.34116547,
    volumeUSD: 4012034.2465140047,
  },
  19275: {
    date: 1665360000,
    tvlUSD: 115103895.41423763,
    volumeUSD: 2726127.987751835,
  },
  19276: {
    date: 1665446400,
    tvlUSD: 115104591.9902032,
    volumeUSD: 3045559.650093257,
  },
  19277: {
    date: 1665532800,
    tvlUSD: 113180818.38362762,
    volumeUSD: 1951492.4809460314,
  },
  19278: {
    date: 1665619200,
    tvlUSD: 112490666.73911692,
    volumeUSD: 3632820.058130127,
  },
  19279: {
    date: 1665705600,
    tvlUSD: 112022442.235709,
    volumeUSD: 2332139.575176323,
  },
  19280: {
    date: 1665792000,
    tvlUSD: 105913340.12016815,
    volumeUSD: 1458932.2757048286,
  },
  19281: {
    date: 1665878400,
    tvlUSD: 109291594.29116075,
    volumeUSD: 1361930.592987505,
  },
  19282: {
    date: 1665964800,
    tvlUSD: 109393839.70151071,
    volumeUSD: 2075176.2360927707,
  },
  19283: {
    date: 1666051200,
    tvlUSD: 108363014.08110486,
    volumeUSD: 2236924.630724224,
  },
  19284: {
    date: 1666137600,
    tvlUSD: 112604762.85156016,
    volumeUSD: 1517439.3240755494,
  },
  19285: {
    date: 1666224000,
    tvlUSD: 113335959.67977725,
    volumeUSD: 2182036.867792434,
  },
  19286: {
    date: 1666310400,
    tvlUSD: 128264729.51675637,
    volumeUSD: 2363918.059609637,
  },
  19287: {
    date: 1666396800,
    tvlUSD: 134695711.71311754,
    volumeUSD: 3798610.3681890042,
  },
  19288: {
    date: 1666483200,
    tvlUSD: 140967503.70726508,
    volumeUSD: 2010914.3495357977,
  },
  19289: {
    date: 1666569600,
    tvlUSD: 152374768.32953864,
    volumeUSD: 2870166.1207711864,
  },
  19290: {
    date: 1666656000,
    tvlUSD: 168622001.26145703,
    volumeUSD: 3804600.8336492013,
  },
  19291: {
    date: 1666742400,
    tvlUSD: 170117488.00031513,
    volumeUSD: 2947592.7216297705,
  },
  19292: {
    date: 1666828800,
    tvlUSD: 176460778.8215363,
    volumeUSD: 5114922.360257325,
  },
  19293: {
    date: 1666915200,
    tvlUSD: 179614958.2971799,
    volumeUSD: 4564150.748972797,
  },
  19294: {
    date: 1667001600,
    tvlUSD: 181459293.22909114,
    volumeUSD: 4026841.554163361,
  },
  19295: {
    date: 1667088000,
    tvlUSD: 180371828.38971567,
    volumeUSD: 2148730.567283363,
  },
  19296: {
    date: 1667174400,
    tvlUSD: 175162491.2316575,
    volumeUSD: 6924195.838584786,
  },
  19297: {
    date: 1667260800,
    tvlUSD: 173865496.00427473,
    volumeUSD: 7087117.811901461,
  },
  19298: {
    date: 1667347200,
    tvlUSD: 174971210.0357077,
    volumeUSD: 3313591.7071262184,
  },
  19299: {
    date: 1667433600,
    tvlUSD: 174523496.29620284,
    volumeUSD: 2906802.460464414,
  },
  19300: {
    date: 1667520000,
    tvlUSD: 185141017.97037727,
    volumeUSD: 6758986.644919767,
  },
  19301: {
    date: 1667606400,
    tvlUSD: 188372354.9171511,
    volumeUSD: 1961328.0494893307,
  },
  19302: {
    date: 1667692800,
    tvlUSD: 184043160.19412488,
    volumeUSD: 2316602.202240101,
  },
  19303: {
    date: 1667779200,
    tvlUSD: 204288258.88222522,
    volumeUSD: 4461373.158062116,
  },
  19304: {
    date: 1667865600,
    tvlUSD: 192403888.06038395,
    volumeUSD: 19220472.593602225,
  },
  19305: {
    date: 1667952000,
    tvlUSD: 154406574.85570547,
    volumeUSD: 93336879.63853823,
  },
  19306: {
    date: 1668038400,
    tvlUSD: 131957801.77776903,
    volumeUSD: 213035942.54086298,
  },
  19307: {
    date: 1668124800,
    tvlUSD: 135317905.0537843,
    volumeUSD: 46958462.441336736,
  },
  19308: {
    date: 1668211200,
    tvlUSD: 135432398.92058054,
    volumeUSD: 75713421.89754607,
  },
  19309: {
    date: 1668297600,
    tvlUSD: 136102898.44638076,
    volumeUSD: 45264042.01472846,
  },
  19310: {
    date: 1668384000,
    tvlUSD: 140302505.48437998,
    volumeUSD: 21124289.976440154,
  },
  19311: {
    date: 1668470400,
    tvlUSD: 145111493.94143432,
    volumeUSD: 18387554.709704407,
  },
  19312: {
    date: 1668556800,
    tvlUSD: 148944296.67441738,
    volumeUSD: 22891037.349704985,
  },
  19313: {
    date: 1668643200,
    tvlUSD: 151421761.87300584,
    volumeUSD: 12294996.817977762,
  },
  19314: {
    date: 1668729600,
    tvlUSD: 154515110.4148343,
    volumeUSD: 10746442.495933749,
  },
  19315: {
    date: 1668816000,
    tvlUSD: 152481618.1832737,
    volumeUSD: 13174469.155420952,
  },
  19316: {
    date: 1668902400,
    tvlUSD: 149344364.8938417,
    volumeUSD: 11220539.462254,
  },
  19317: {
    date: 1668988800,
    tvlUSD: 146166730.32612097,
    volumeUSD: 15608416.573563682,
  },
  19318: {
    date: 1669075200,
    tvlUSD: 147088948.47014862,
    volumeUSD: 9314414.615670385,
  },
  19319: {
    date: 1669161600,
    tvlUSD: 139871133.601441,
    volumeUSD: 21258421.73392678,
  },
  19320: {
    date: 1669248000,
    tvlUSD: 137315586.46261454,
    volumeUSD: 14646487.336646467,
  },
  19321: {
    date: 1669334400,
    tvlUSD: 140522510.70951104,
    volumeUSD: 8691346.70889867,
  },
  19322: {
    date: 1669420800,
    tvlUSD: 160750900.85907748,
    volumeUSD: 6201449.529830717,
  },
  19323: {
    date: 1669507200,
    tvlUSD: 160798524.29440978,
    volumeUSD: 4840089.169084559,
  },
  19324: {
    date: 1669593600,
    tvlUSD: 152263392.7730196,
    volumeUSD: 11514214.729563149,
  },
  19325: {
    date: 1669680000,
    tvlUSD: 154421521.1094198,
    volumeUSD: 6161122.238860562,
  },
  19326: {
    date: 1669766400,
    tvlUSD: 157488624.72756937,
    volumeUSD: 11053190.47533756,
  },
  19327: {
    date: 1669852800,
    tvlUSD: 180631799.80829352,
    volumeUSD: 6806192.067974986,
  },
  19328: {
    date: 1669939200,
    tvlUSD: 122549353.59272626,
    volumeUSD: 14572354.70452173,
  },
  19329: {
    date: 1670025600,
    tvlUSD: 126673383.71151252,
    volumeUSD: 3004894.7720048563,
  },
  19330: {
    date: 1670112000,
    tvlUSD: 129191542.95220453,
    volumeUSD: 2770242.9947125646,
  },
  19331: {
    date: 1670198400,
    tvlUSD: 129800072.07304427,
    volumeUSD: 3704651.279126917,
  },
  19332: {
    date: 1670284800,
    tvlUSD: 131029140.19592987,
    volumeUSD: 9239670.549683474,
  },
  19333: {
    date: 1670371200,
    tvlUSD: 131229994.72519368,
    volumeUSD: 2894694.201005536,
  },
  19334: {
    date: 1670457600,
    tvlUSD: 140376148.99553287,
    volumeUSD: 3445542.1953945,
  },
  19335: {
    date: 1670544000,
    tvlUSD: 144640466.71091118,
    volumeUSD: 3307883.8330746773,
  },
  19336: {
    date: 1670630400,
    tvlUSD: 144751281.5697057,
    volumeUSD: 7573546.717106456,
  },
  19337: {
    date: 1670716800,
    tvlUSD: 143318269.74916205,
    volumeUSD: 3733356.294105728,
  },
  19338: {
    date: 1670803200,
    tvlUSD: 128547419.0464781,
    volumeUSD: 6171043.876484212,
  },
  19339: {
    date: 1670889600,
    tvlUSD: 119119529.95000528,
    volumeUSD: 37782791.69091805,
  },
  19340: {
    date: 1670976000,
    tvlUSD: 124732727.10019547,
    volumeUSD: 8950793.990677757,
  },
  19341: {
    date: 1671062400,
    tvlUSD: 119593128.16081774,
    volumeUSD: 4705745.199698457,
  },
  19342: {
    date: 1671148800,
    tvlUSD: 119994485.740383,
    volumeUSD: 11993862.089311915,
  },
  19343: {
    date: 1671235200,
    tvlUSD: 110349137.40359548,
    volumeUSD: 19310368.956669588,
  },
  19344: {
    date: 1671321600,
    tvlUSD: 110264719.1383916,
    volumeUSD: 3393680.3034330807,
  },
  19345: {
    date: 1671408000,
    tvlUSD: 110583309.29195534,
    volumeUSD: 5607914.77583178,
  },
  19346: {
    date: 1671494400,
    tvlUSD: 111689246.51229303,
    volumeUSD: 10345714.408615604,
  },
  19347: {
    date: 1671580800,
    tvlUSD: 110435707.77771153,
    volumeUSD: 6595280.639311984,
  },
  19348: {
    date: 1671667200,
    tvlUSD: 114976304.90607773,
    volumeUSD: 2249796.060341353,
  },
  19349: {
    date: 1671753600,
    tvlUSD: 114982416.43779789,
    volumeUSD: 1401676.13297675,
  },
  19350: {
    date: 1671840000,
    tvlUSD: 110436334.14343247,
    volumeUSD: 1253801.5754480455,
  },
  19351: {
    date: 1671926400,
    tvlUSD: 105686689.85275593,
    volumeUSD: 2234574.73916629,
  },
  19352: {
    date: 1672012800,
    tvlUSD: 104190718.5909959,
    volumeUSD: 4073119.4398718565,
  },
  19353: {
    date: 1672099200,
    tvlUSD: 104566060.84774704,
    volumeUSD: 3456242.0438492633,
  },
  19354: {
    date: 1672185600,
    tvlUSD: 104457041.23373121,
    volumeUSD: 5210152.450486411,
  },
  19355: {
    date: 1672272000,
    tvlUSD: 104455005.49012846,
    volumeUSD: 3675180.6317488356,
  },
  19356: {
    date: 1672358400,
    tvlUSD: 103407548.3110458,
    volumeUSD: 1936131.4354590627,
  },
  19357: {
    date: 1672444800,
    tvlUSD: 100701885.56362946,
    volumeUSD: 2513873.7156480714,
  },
  19358: {
    date: 1672531200,
    tvlUSD: 97764046.85515521,
    volumeUSD: 7425212.596068421,
  },
  19359: {
    date: 1672617600,
    tvlUSD: 96086985.67149174,
    volumeUSD: 3545269.88346347,
  },
  19360: {
    date: 1672704000,
    tvlUSD: 95594272.42560238,
    volumeUSD: 3682547.994058085,
  },
  19361: {
    date: 1672790400,
    tvlUSD: 93111840.93988529,
    volumeUSD: 2787458.234215914,
  },
  19362: {
    date: 1672876800,
    tvlUSD: 94147501.70797643,
    volumeUSD: 44229317.59923501,
  },
  19363: {
    date: 1672963200,
    tvlUSD: 94756274.02036469,
    volumeUSD: 3341262.6728506074,
  },
  19364: {
    date: 1673049600,
    tvlUSD: 97049529.81119537,
    volumeUSD: 1959800.8033443664,
  },
  19365: {
    date: 1673136000,
    tvlUSD: 97027321.92691877,
    volumeUSD: 3389709.037053352,
  },
  19366: {
    date: 1673222400,
    tvlUSD: 96814212.8046454,
    volumeUSD: 3764088.7084937827,
  },
  19367: {
    date: 1673308800,
    tvlUSD: 99876718.2641624,
    volumeUSD: 3650115.7345832093,
  },
  19368: {
    date: 1673395200,
    tvlUSD: 97522859.98485306,
    volumeUSD: 3690812.472490502,
  },
  19369: {
    date: 1673481600,
    tvlUSD: 95158491.76499109,
    volumeUSD: 10074378.077152416,
  },
  19370: {
    date: 1673568000,
    tvlUSD: 95867779.99666744,
    volumeUSD: 3747346.7928637727,
  },
  19371: {
    date: 1673654400,
    tvlUSD: 94363607.69963214,
    volumeUSD: 8342461.756038946,
  },
  19372: {
    date: 1673740800,
    tvlUSD: 91096185.97332337,
    volumeUSD: 6199174.650037599,
  },
  19373: {
    date: 1673827200,
    tvlUSD: 93412883.49287602,
    volumeUSD: 5007188.99175537,
  },
  19374: {
    date: 1673913600,
    tvlUSD: 92234751.28119336,
    volumeUSD: 2843728.679954802,
  },
  19375: {
    date: 1674000000,
    tvlUSD: 86595801.83319886,
    volumeUSD: 12537766.232591623,
  },
  19376: {
    date: 1674086400,
    tvlUSD: 91772568.85625836,
    volumeUSD: 4708224.404937677,
  },
  19377: {
    date: 1674172800,
    tvlUSD: 87942549.2806028,
    volumeUSD: 4435084.44554577,
  },
  19378: {
    date: 1674259200,
    tvlUSD: 87902085.28944297,
    volumeUSD: 5223539.979782515,
  },
  19379: {
    date: 1674345600,
    tvlUSD: 88771380.49177112,
    volumeUSD: 3103328.382307,
  },
  19380: {
    date: 1674432000,
    tvlUSD: 89651698.54507306,
    volumeUSD: 2603052.936872311,
  },
  19381: {
    date: 1674518400,
    tvlUSD: 88305869.37351008,
    volumeUSD: 4170592.5544841764,
  },
  19382: {
    date: 1674604800,
    tvlUSD: 88624880.54195279,
    volumeUSD: 3697180.6429811986,
  },
  19383: {
    date: 1674691200,
    tvlUSD: 89947997.49803811,
    volumeUSD: 3381771.3193419813,
  },
  19384: {
    date: 1674777600,
    tvlUSD: 89270471.1922233,
    volumeUSD: 6026000.483628141,
  },
  19385: {
    date: 1674864000,
    tvlUSD: 90208052.66363099,
    volumeUSD: 2470219.0543098324,
  },
  19386: {
    date: 1674950400,
    tvlUSD: 89124291.56064387,
    volumeUSD: 4700504.658139034,
  },
  19387: {
    date: 1675036800,
    tvlUSD: 88829810.12561445,
    volumeUSD: 4733463.810711905,
  },
  19388: {
    date: 1675123200,
    tvlUSD: 84308494.29592551,
    volumeUSD: 5622635.702082442,
  },
  19389: {
    date: 1675209600,
    tvlUSD: 83934410.40915416,
    volumeUSD: 2151989.8130789287,
  },
  19390: {
    date: 1675296000,
    tvlUSD: 75685073.56236304,
    volumeUSD: 7078101.215475502,
  },
  19391: {
    date: 1675382400,
    tvlUSD: 77402218.14197206,
    volumeUSD: 4346558.204129171,
  },
  19392: {
    date: 1675468800,
    tvlUSD: 74882255.55169205,
    volumeUSD: 3335696.7766144946,
  },
  19393: {
    date: 1675555200,
    tvlUSD: 73336733.22284324,
    volumeUSD: 4061389.2414809153,
  },
  19394: {
    date: 1675641600,
    tvlUSD: 72898289.86172593,
    volumeUSD: 3965994.0357937636,
  },
  19395: {
    date: 1675728000,
    tvlUSD: 72380123.95775282,
    volumeUSD: 5537109.910648945,
  },
  19396: {
    date: 1675814400,
    tvlUSD: 68303513.71950558,
    volumeUSD: 4082098.9960481855,
  },
  19397: {
    date: 1675900800,
    tvlUSD: 67931401.7417318,
    volumeUSD: 4868396.580007499,
  },
  19398: {
    date: 1675987200,
    tvlUSD: 67814636.62821078,
    volumeUSD: 2538769.6935216505,
  },
  19399: {
    date: 1676073600,
    tvlUSD: 67163701.26439542,
    volumeUSD: 1950127.215544498,
  },
  19400: {
    date: 1676160000,
    tvlUSD: 67880236.15898895,
    volumeUSD: 1815724.7957285866,
  },
  19401: {
    date: 1676246400,
    tvlUSD: 49470322.93177926,
    volumeUSD: 38263358.37091532,
  },
  19402: {
    date: 1676332800,
    tvlUSD: 51652497.4475555,
    volumeUSD: 14208813.579987776,
  },
  19403: {
    date: 1676419200,
    tvlUSD: 52970078.69743377,
    volumeUSD: 5914791.740651432,
  },
  19404: {
    date: 1676505600,
    tvlUSD: 54722458.692899846,
    volumeUSD: 6103804.6855089655,
  },
  19405: {
    date: 1676592000,
    tvlUSD: 55236345.387109995,
    volumeUSD: 5122670.201185826,
  },
  19406: {
    date: 1676678400,
    tvlUSD: 54439071.16573439,
    volumeUSD: 3616843.9588461956,
  },
  19407: {
    date: 1676764800,
    tvlUSD: 54034457.463222094,
    volumeUSD: 3521906.145245264,
  },
  19408: {
    date: 1676851200,
    tvlUSD: 50555512.909374714,
    volumeUSD: 5171842.674154922,
  },
  19409: {
    date: 1676937600,
    tvlUSD: 51976620.370029695,
    volumeUSD: 4972407.482163175,
  },
  19410: {
    date: 1677024000,
    tvlUSD: 53177234.325074926,
    volumeUSD: 6447451.196466374,
  },
  19411: {
    date: 1677110400,
    tvlUSD: 52819383.415916316,
    volumeUSD: 4192642.4470253736,
  },
  19412: {
    date: 1677196800,
    tvlUSD: 52843299.75378156,
    volumeUSD: 6727129.424862213,
  },
  19413: {
    date: 1677283200,
    tvlUSD: 53062147.68166392,
    volumeUSD: 4629381.316210437,
  },
  19414: {
    date: 1677369600,
    tvlUSD: 54456971.055345565,
    volumeUSD: 3056823.6296792324,
  },
  19415: {
    date: 1677456000,
    tvlUSD: 53476362.50030649,
    volumeUSD: 4790167.689304784,
  },
  19416: {
    date: 1677542400,
    tvlUSD: 53398156.59665417,
    volumeUSD: 3099530.1331833163,
  },
  19417: {
    date: 1677628800,
    tvlUSD: 57270633.81011944,
    volumeUSD: 5203746.03674974,
  },
  19418: {
    date: 1677715200,
    tvlUSD: 56958131.632124394,
    volumeUSD: 6443036.1384876035,
  },
  19419: {
    date: 1677801600,
    tvlUSD: 51959751.57750708,
    volumeUSD: 13591962.344464023,
  },
  19420: {
    date: 1677888000,
    tvlUSD: 55648194.445867606,
    volumeUSD: 4381715.550387731,
  },
  19421: {
    date: 1677974400,
    tvlUSD: 58196184.81818956,
    volumeUSD: 7574129.336837226,
  },
  19422: {
    date: 1678060800,
    tvlUSD: 58649605.25425284,
    volumeUSD: 6657914.905124871,
  },
  19423: {
    date: 1678147200,
    tvlUSD: 55675056.32619427,
    volumeUSD: 5603915.574973949,
  },
  19424: {
    date: 1678233600,
    tvlUSD: 55730824.42259283,
    volumeUSD: 7089667.094979667,
  },
  19425: {
    date: 1678320000,
    tvlUSD: 55152392.26199279,
    volumeUSD: 8640185.437210966,
  },
  19426: {
    date: 1678406400,
    tvlUSD: 54121239.78300134,
    volumeUSD: 22342347.61140976,
  },
  19427: {
    date: 1678492800,
    tvlUSD: 42099727.11439369,
    volumeUSD: 166402615.7095934,
  },
  19428: {
    date: 1678579200,
    tvlUSD: 42863433.74574747,
    volumeUSD: 32904918.87430262,
  },
  19429: {
    date: 1678665600,
    tvlUSD: 43611190.89174765,
    volumeUSD: 55179487.15773038,
  },
  19430: {
    date: 1678752000,
    tvlUSD: 48451994.08512308,
    volumeUSD: 31098623.458991643,
  },
  19431: {
    date: 1678838400,
    tvlUSD: 47379107.54390974,
    volumeUSD: 29183981.75651424,
  },
  19432: {
    date: 1678924800,
    tvlUSD: 42759534.35563803,
    volumeUSD: 22050142.47468686,
  },
  19433: {
    date: 1679011200,
    tvlUSD: 44053625.64298958,
    volumeUSD: 21521282.18757704,
  },
  19434: {
    date: 1679097600,
    tvlUSD: 44442472.60198169,
    volumeUSD: 18608880.548031773,
  },
  19435: {
    date: 1679184000,
    tvlUSD: 47130143.36810265,
    volumeUSD: 12473873.546416193,
  },
  19436: {
    date: 1679270400,
    tvlUSD: 45824907.17033868,
    volumeUSD: 21513595.49892979,
  },
  19437: {
    date: 1679356800,
    tvlUSD: 45266359.301242046,
    volumeUSD: 21643914.935642596,
  },
  19438: {
    date: 1679443200,
    tvlUSD: 45661620.04094436,
    volumeUSD: 24512189.6114198,
  },
  19439: {
    date: 1679529600,
    tvlUSD: 44448609.65251585,
    volumeUSD: 19783775.249523953,
  },
  19440: {
    date: 1679616000,
    tvlUSD: 46862519.31754078,
    volumeUSD: 21488009.690273378,
  },
  19441: {
    date: 1679702400,
    tvlUSD: 47295314.79453138,
    volumeUSD: 10041369.856362427,
  },
  19442: {
    date: 1679788800,
    tvlUSD: 47204190.60578097,
    volumeUSD: 9837781.738841405,
  },
  19443: {
    date: 1679875200,
    tvlUSD: 47030725.809636995,
    volumeUSD: 11922991.148490215,
  },
  19444: {
    date: 1679961600,
    tvlUSD: 48320957.19906851,
    volumeUSD: 10136000.97081693,
  },
  19445: {
    date: 1680048000,
    tvlUSD: 49644663.41234103,
    volumeUSD: 7644467.924340283,
  },
  19446: {
    date: 1680134400,
    tvlUSD: 48064583.439846925,
    volumeUSD: 8414153.480964677,
  },
  19447: {
    date: 1680220800,
    tvlUSD: 48035041.24719584,
    volumeUSD: 6440088.614226497,
  },
  19448: {
    date: 1680307200,
    tvlUSD: 48345714.51367359,
    volumeUSD: 10968347.112817587,
  },
  19449: {
    date: 1680393600,
    tvlUSD: 48055298.572321646,
    volumeUSD: 7365289.201174199,
  },
  19450: {
    date: 1680480000,
    tvlUSD: 47430625.90795766,
    volumeUSD: 12200204.99230333,
  },
  19451: {
    date: 1680566400,
    tvlUSD: 46855917.558799334,
    volumeUSD: 6465499.597090274,
  },
  19452: {
    date: 1680652800,
    tvlUSD: 45222400.85330082,
    volumeUSD: 6561303.333791588,
  },
  19453: {
    date: 1680739200,
    tvlUSD: 44314609.498814575,
    volumeUSD: 8248257.619554492,
  },
  19454: {
    date: 1680825600,
    tvlUSD: 43655791.91905888,
    volumeUSD: 5653389.4876037985,
  },
  19455: {
    date: 1680912000,
    tvlUSD: 43835955.10770015,
    volumeUSD: 4996475.484752752,
  },
  19456: {
    date: 1680998400,
    tvlUSD: 43926877.46244111,
    volumeUSD: 4197293.7334907,
  },
  19457: {
    date: 1681084800,
    tvlUSD: 45663315.42211794,
    volumeUSD: 6167640.1784729455,
  },
  19458: {
    date: 1681171200,
    tvlUSD: 47468386.492077924,
    volumeUSD: 7848706.313456707,
  },
  19459: {
    date: 1681257600,
    tvlUSD: 47429308.76179226,
    volumeUSD: 4039401.9812079356,
  },
  19460: {
    date: 1681344000,
    tvlUSD: 47791533.753514186,
    volumeUSD: 7480794.08004679,
  },
  19461: {
    date: 1681430400,
    tvlUSD: 47824450.920354806,
    volumeUSD: 5651221.68066795,
  },
  19462: {
    date: 1681516800,
    tvlUSD: 46635383.34119857,
    volumeUSD: 5275410.831521562,
  },
  19463: {
    date: 1681603200,
    tvlUSD: 46346146.183028445,
    volumeUSD: 7876299.13587108,
  },
  19464: {
    date: 1681689600,
    tvlUSD: 47590595.83045482,
    volumeUSD: 7916796.505415991,
  },
  19465: {
    date: 1681776000,
    tvlUSD: 48203917.75316942,
    volumeUSD: 7154927.136147682,
  },
  19466: {
    date: 1681862400,
    tvlUSD: 50338636.461798936,
    volumeUSD: 7188197.436761502,
  },
  19467: {
    date: 1681948800,
    tvlUSD: 49127166.4820314,
    volumeUSD: 6231355.303344363,
  },
  19468: {
    date: 1682035200,
    tvlUSD: 48378336.74484934,
    volumeUSD: 9242323.591060448,
  },
  19469: {
    date: 1682121600,
    tvlUSD: 49305306.97645876,
    volumeUSD: 4596844.823401901,
  },
  19470: {
    date: 1682208000,
    tvlUSD: 50359425.4652378,
    volumeUSD: 4282451.586387039,
  },
  19471: {
    date: 1682294400,
    tvlUSD: 50523400.5627757,
    volumeUSD: 5280590.640024378,
  },
  19472: {
    date: 1682380800,
    tvlUSD: 52257197.055425666,
    volumeUSD: 4635765.603416781,
  },
  19473: {
    date: 1682467200,
    tvlUSD: 49260204.35695705,
    volumeUSD: 4680319.7829568805,
  },
  19474: {
    date: 1682553600,
    tvlUSD: 50674888.01016317,
    volumeUSD: 4161603.5859518307,
  },
  19475: {
    date: 1682640000,
    tvlUSD: 53700559.16658577,
    volumeUSD: 7147569.903854609,
  },
  19476: {
    date: 1682726400,
    tvlUSD: 53035812.07568897,
    volumeUSD: 3222327.6713970327,
  },
  19477: {
    date: 1682812800,
    tvlUSD: 48812827.14952997,
    volumeUSD: 4347287.349709482,
  },
  19478: {
    date: 1682899200,
    tvlUSD: 48276396.4719022,
    volumeUSD: 6171002.151984814,
  },
  19479: {
    date: 1682985600,
    tvlUSD: 50390624.169119984,
    volumeUSD: 5397183.914636537,
  },
  19480: {
    date: 1683072000,
    tvlUSD: 52461674.026226506,
    volumeUSD: 7282210.598546126,
  },
  19481: {
    date: 1683158400,
    tvlUSD: 47792828.74878876,
    volumeUSD: 4908843.907840992,
  },
  19482: {
    date: 1683244800,
    tvlUSD: 49000699.17143213,
    volumeUSD: 7521782.605374177,
  },
  19483: {
    date: 1683331200,
    tvlUSD: 47894187.30405753,
    volumeUSD: 8794827.232471637,
  },
  19484: {
    date: 1683417600,
    tvlUSD: 48026744.29883738,
    volumeUSD: 4624906.063053777,
  },
  19485: {
    date: 1683504000,
    tvlUSD: 48089593.37471798,
    volumeUSD: 9353036.026705204,
  },
  19486: {
    date: 1683590400,
    tvlUSD: 48150708.10799023,
    volumeUSD: 4823678.541390131,
  },
  19487: {
    date: 1683676800,
    tvlUSD: 54023176.33666203,
    volumeUSD: 4538149.425245221,
  },
  19488: {
    date: 1683763200,
    tvlUSD: 53905196.74014961,
    volumeUSD: 5162735.311888164,
  },
  19489: {
    date: 1683849600,
    tvlUSD: 54534085.5072981,
    volumeUSD: 3155401.229663924,
  },
  19490: {
    date: 1683936000,
    tvlUSD: 54307885.41778238,
    volumeUSD: 2261736.7808455364,
  },
  19491: {
    date: 1684022400,
    tvlUSD: 54102971.362189285,
    volumeUSD: 2570960.3836050443,
  },
  19492: {
    date: 1684108800,
    tvlUSD: 54743637.75506603,
    volumeUSD: 3199718.172125403,
  },
  19493: {
    date: 1684195200,
    tvlUSD: 54498348.887131155,
    volumeUSD: 2596661.5956322397,
  },
  19494: {
    date: 1684281600,
    tvlUSD: 54694231.11396429,
    volumeUSD: 4464292.517883233,
  },
  19495: {
    date: 1684368000,
    tvlUSD: 55016122.686076835,
    volumeUSD: 5216717.267239106,
  },
  19496: {
    date: 1684454400,
    tvlUSD: 57248403.13258044,
    volumeUSD: 2663001.1662970814,
  },
  19497: {
    date: 1684540800,
    tvlUSD: 57427952.35356198,
    volumeUSD: 2370222.7276048325,
  },
  19498: {
    date: 1684627200,
    tvlUSD: 57395935.51549582,
    volumeUSD: 2609162.2928268933,
  },
}
