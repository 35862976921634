import React, { useMemo } from 'react'
import styled from 'styled-components'
import wstETHLogo from '../../wombat-assets/tokens/wstETH.svg'
import BUSDLogo from '../../wombat-assets/tokens/BUSD.svg'
import DAILogo from '../../wombat-assets/tokens/DAI.svg'
import USDCLogo from '../../wombat-assets/tokens/USDC.svg'
import USDTLogo from '../../wombat-assets/tokens/USDT.svg'
import WBNBLogo from '../../wombat-assets/tokens/WBNB.svg'
import BNBxLogo from '../../wombat-assets/tokens/BNBx.svg'
import stkBNBLogo from '../../wombat-assets/tokens/stkBNB.svg'
import aBNBcLogo from '../../wombat-assets/tokens/aBNBc.svg'
import HAYLogo from '../../wombat-assets/tokens/HAY.svg'
import WOMLogo from '../../wombat-assets/tokens/WOM.svg'
import qWOMLogo from '../../wombat-assets/tokens/qWOM.svg'
import mWOMogo from '../../wombat-assets/tokens/mWOM.svg'
import wmxWOMLogo from '../../wombat-assets/tokens/wmxWOM.svg'
import veWOMLogo from '.../../wombat-assets/tokens/veWOM.svg'
import WMXLogo from '../../wombat-assets/tokens/WMX.svg'
import QUOLLLogo from '../../wombat-assets/tokens/QUOLL.svg'
import MGPLogo from '../../wombat-assets/tokens/MGP.svg'
import FRAXLogo from '../../wombat-assets/tokens/FRAX.svg'
import TUSDLogo from '../../wombat-assets/tokens/TUSD.svg'
import iUSDLogo from '../../wombat-assets/tokens/iUSD.svg'
import CUSDLogo from '../../wombat-assets/tokens/CUSD.svg'
import axlUSDCLogo from '../../wombat-assets/tokens/axlUSDC.svg'
import USDDLogo from '../../wombat-assets/tokens/USDD.svg'
import BOBLogo from '../../wombat-assets/tokens/BOB.svg'
import WETHLogo from '../../wombat-assets/tokens/WETH.svg'
import frxETHLogo from '../../wombat-assets/tokens/FRXETH.svg'
import ETHLogo from '../../wombat-assets/tokens/ETH.svg'
import USDPlusLogo from '../../wombat-assets/tokens/USDplus.svg'
import USDTPlusLogo from '../../wombat-assets/tokens/USDTplus.svg'
import DAIPlusLogo from '../../wombat-assets/tokens/DAIplus.svg'
import MIMLogo from '../../wombat-assets/tokens/MIM.svg'
import MAILogo from '../../wombat-assets/tokens/MAI.svg'
import sfrxETHLogo from '../../wombat-assets/tokens/sfrxETH.svg'
import BNByLogo from '../../wombat-assets/tokens/BNBy.svg'
import WBETHLogo from '../../wombat-assets/tokens/wBETH.svg'
import fUSDCLogo from '../../wombat-assets/tokens/fUSDC.svg'
import ankrETHLogo from '../../wombat-assets/tokens/ankrETH.svg'
import jUSDCLogo from '../../wombat-assets/tokens/jUSDC.svg'
import TBDLogo from '../../wombat-assets/tokens/TBD.svg'

const StyledWombatLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

export default function CurrencyLogo({
  address,
  size = '32px',
  style,
  ...rest
}: {
  address?: string
  size?: string
  style?: React.CSSProperties
}) {
  // @TODO: Refactor
  const wombatLogo = useMemo(() => {
    switch (address) {
      case '0xe9e7cea3dedca5984780bafc599bd69add087d56':
        return BUSDLogo
      case '0xaf88d065e77c8cc2239327c5edb3a432268e5831':
      case '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d':
      case '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8':
        return USDCLogo
      case '0x55d398326f99059ff775485246999027b3197955':
      case '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9':
        return USDTLogo
      case '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3':
      case '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1':
        return DAILogo
      case '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c':
        return WBNBLogo
      case '0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275':
        return BNBxLogo
      case '0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16':
        return stkBNBLogo
      case '0xe85afccdafbe7f2b096f268e31cce3da8da2990a':
      case '0x52f24a5e03aee338da5fd9df68d2b6fae1178827': // ankrBNB
        return aBNBcLogo
      case '0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5':
        return HAYLogo
      case '0x0fe34b8aaaf3f522a6088e278936d10f934c0b19':
      case '0x388d157f0bfdc1d30357af63a8be10bff8474f4e':
        return qWOMLogo
      case '0xad6742a35fb341a9cc6ad674738dd8da98b94fb1':
      case '0x7b5eb3940021ec0e8e463d5dbb4b7b09a89ddf96':
        return WOMLogo
      case '0x027a9d301fb747cd972cfb29a63f3bda551dfc5c':
      case '0x509fd25ee2ac7833a017f17ee8a6fb4aaf947876':
        return mWOMogo
      case '0x0415023846ff1c6016c4d9621de12b24b2402979':
      case '0xeff2b1353cdcaa2c3279c2bfde72120c7ffb5e24':
        return wmxWOMLogo
      case '0x3da62816dd31c56d9cdf22c6771ddb892cb5b0cc':
        return veWOMLogo
      case '0xa75d9ca2a0a1d547409d82e1b06618ec284a2ced':
      case '0x5190f06eacefa2c552dc6bd5e763b81c73293293':
        return WMXLogo
      case '0x08b450e4a48c04cdf6db2bd4cf24057f7b9563ff':
      case '0xf00D8790A76ee5A5Dbc10eaCac39151aa2af0331':
        return QUOLLLogo
      case '0xd06716e1ff2e492cc5034c2e81805562dd3b45fa':
      case '0xa61f74247455a40b01b0559ff6274441fafa22a3':
        return MGPLogo
      case '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40':
      case '0x17fc002b466eec40dae837fc4be5c67993ddbd6f':
        return FRAXLogo
      case '0x14016e85a25aeb13065688cafb43044c2ef86784':
        return TUSDLogo
      case '0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d':
        return iUSDLogo
      case '0xfa4ba88cf97e282c505bea095297786c16070129':
        return CUSDLogo
      case '0x4268b8f0b87b6eae5d897996e6b845ddbd99adf3':
        return axlUSDCLogo
      case '0xd17479997f34dd9156deef8f95a52d81d265be9c':
        return USDDLogo
      case '0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b':
        return BOBLogo
      case '0x4db5a66e937a9f4473fa95b1caf1d1e1d62e29ea':
        return WETHLogo
      case '0x64048a7eecf3a2f1ba9e144aac3d7db6e58f555e':
      case '0x178412e79c25968a32e89b11f63b33f733770c2a':
        return frxETHLogo
      case '0x2170ed0880ac9a755fd29b2688956bd959f933f8':
      case '0x82af49447d8a07e3bd95bd0d56f35241523fbab1':
        return ETHLogo
      case '0xfe19f0b51438fd612f6fd59c1dbb3ea319f433ba':
      case '0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a':
        return MIMLogo
      case '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65':
      case '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65':
        return USDPlusLogo
      case '0x5335e87930b410b8c5bb4d43c3360aca15ec0c8c':
        return USDTPlusLogo
      case '0xeb8e93a0c7504bffd8a8ffa56cd754c63aaebfe8':
        return DAIPlusLogo
      case '0x3f56e0c36d275367b8c502090edf38289b3dea0d':
        return MAILogo
      case '0x3cd55356433c89e50dc51ab07ee0fa0a95623d53':
      case '0x95ab45875cffdba1e5f451b950bc2e42c0053f39':
        return sfrxETHLogo
      case '0x6764506be2a755c18f4c70bde4e63f26f9f62810':
        return BNByLogo
      case '0xa2e3356610840701bdf5611a53974510ae27e2e1':
        return WBETHLogo
      case '0x2290281060f2ce6bb73dccf9b1735bc366f3f2c4':
      case '0xe05a08226c49b636acf99c40da8dc6af83ce5bb3':
        return ankrETHLogo
      case '0xe66998533a1992ece9ea99cdf47686f4fc8458e0':
        return jUSDCLogo
      case '0x5979d7b546e38e414f7e9822514be443a4800529':
        return wstETHLogo
      case '0x4cfa50b7ce747e2d61724fcac57f24b748ff2b2a':
        return fUSDCLogo
      default:
        return TBDLogo
    }
  }, [address])
  return <StyledWombatLogo src={wombatLogo} size={size} style={style} {...rest} />
}

export function ProjectLogo({
  address,
  size = '32px',
  style,
  ...rest
}: {
  address?: string
  size?: string
  style?: React.CSSProperties
}) {
  const wombatLogo = useMemo(() => {
    switch (address) {
      case 'Wombex':
        return WMXLogo
      case 'Magpie':
        return MGPLogo
      case 'Quoll':
        return QUOLLLogo
      default:
        return undefined
    }
  }, [address])
  return <StyledWombatLogo src={wombatLogo} size={size} style={style} {...rest} />
}
