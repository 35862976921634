import React, { useState, useEffect } from 'react'
import { BrowserRouter, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../../wombat-assets/wombatLogo/wombat_logo_trans_2.svg'
import LogoMobile from '../../wombat-assets/wombatLogo/wombat_logo_trans_no_text.svg'
import WordmarkAnalytics from '../../wombat-assets/icons/wordmark_analytics.svg'
import Menu from '../Menu'
import Row, { RowFixed } from '../Row'
import WomLogo from '../../wombat-assets/tokens/WOM.svg'
import { formatDollarAmount } from 'utils/numbers'
import { fetchCurrentTokenPrice } from 'hooks/useTokenPrice'
import useTheme from 'hooks/useTheme'
import ButtonBinanceHover from '../../wombat-assets/background/button-dark-short.svg'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { networkPrefix } from 'utils/networkPrefix'
import { TYPE } from 'theme'
import { ROUTE_INFO, WOM_ADDRESS } from '../../constants/index'
import useBreakpoints from 'hooks/useBreakpoints'
import NetworkDropdown from 'components/Menu/NetworkDropdown'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 76px;
  top: 0;
  position: relative;
  padding-left: 84px;
  padding-right: 84px;
  z-index: 2;

  background-color: #f5f3f6;

  @media (max-width: 1080px) {
    padding: 0.5rem 1rem;
    width: calc(100%);
    position: relative;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0.5rem 1rem;
  `}
`

const HeaderLinks = styled(Row)`
  justify-content: flex-start;
  gap: 6px;
  font-family: 'Work Sans', sans-serif;
  @media (max-width: 1080px) {
    padding: 0.5rem;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
   display: none;
  `}
`

const LogoWrapper = styled.div`
  margin-right: 1rem;
`
const Title = styled(NavLink)`
  margin-bottom: 0.25rem;
  margin-right: 1rem;
  min-width: 105px;
  min-height: 25px;
  background: url(${Logo}) center/contain no-repeat;
  :hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-right: 0.625rem;
  min-width: 29px; 
  min-height: 27px; 
  background-image: url(${LogoMobile})`};
  flex-direction: column;
`

const WomPriceWrapper = styled(RowFixed)<{ isWomPriceDefined: boolean }>`
  justify-content: flex-end;
  margin-right: 70px;
  display: ${({ isWomPriceDefined }) => (isWomPriceDefined ? `flex` : `none`)};
  ${({ theme }) => theme.mediaWidth.upToSmall`display: none`};
`
const StyledBinanceButton = styled.button`
  padding: 5px;
  margin: 0.25rem 3px 0px;
  height: 1.75rem;
  background-color: ${({ theme }) => theme.wombatPurple1};
  border: none;
  color: ${({ theme }) => theme.wombatCream};
  font-size: 0.875rem;
  width: fit-content;

  // TODO: uncomment when cross-chain is supported
  // :hover {
  //   margin: 0px;
  //   width: 100%;
  //   height: 2rem;
  //   background: url(${ButtonBinanceHover}) center/contain no-repeat transparent;
  // }
  ${({ theme }) => theme.mediaWidth.upToSmall`

  // TODO: uncomment when cross-chain is supported
  // :hover {
  //   height: 1.75rem;
  // }
  `};
`

const activeClassName = 'ACTIVE'
const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 1rem;
  width: fit-content;
  padding: 8px 12px;
  font-weight: 500;
  &.${activeClassName} {
    border-radius: 12px;
    color: ${({ theme }) => theme.yellow3};
  }
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const Vertical = styled.div`
  height: 30px;
  border: 1px solid #b672d6;
  margin: 5px auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`display: none`}
`

export default function Header() {
  const [activeNewtork] = useActiveNetworkVersion()
  // @TODO: Fix the priceFeed of $WOM. It's now still $0.2
  const [womPrice, setWomPrice] = useState<number | undefined>(undefined)
  const theme = useTheme()
  const { isTabletSm } = useBreakpoints()

  // useEffect(() => {
  //   async function fetchWomPrice() {
  //     const price = await fetchCurrentTokenPrice(WOM_ADDRESS)
  //     setWomPrice(price)
  //   }
  //   fetchWomPrice()

  //   const id = setInterval(async () => {
  //     await fetchWomPrice()
  //   }, 20000)
  //   return () => {
  //     clearInterval(id)
  //   }
  // }, [])

  return (
    <HeaderFrame>
      <LogoWrapper>
        <RowFixed>
          <BrowserRouter forceRefresh={true}>
            <Title to={networkPrefix(activeNewtork)} />
          </BrowserRouter>
          <img width={'108px'} src={WordmarkAnalytics} alt="analytics wordmark" />
        </RowFixed>
      </LogoWrapper>
      <Vertical />
      <HeaderLinks>
        {ROUTE_INFO.map((route, index) => {
          return (
            <StyledNavLink to={networkPrefix(activeNewtork) + route.pathName} key={index}>
              {route.name}
            </StyledNavLink>
          )
        })}
      </HeaderLinks>
      <RowFixed>
        {/* <WomPriceWrapper isWomPriceDefined={womPrice !== undefined}>
          <img width={'27px'} src={WomLogo} alt="WOM logo" />
          <TYPE.body fontWeight="500" color={theme.wombatBrown1} ml="4px">
            {formatDollarAmount(womPrice, 3)}
          </TYPE.body>
        </WomPriceWrapper> */}
        {/* <Polling /> */}
        <NetworkDropdown></NetworkDropdown>
        {!isTabletSm && <Menu />}
      </RowFixed>
    </HeaderFrame>
  )
}
