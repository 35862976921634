import { useAllAssetData, useUpdateAssetData, useAddAssetKeys } from './hooks'
import { useEffect, useMemo } from 'react'
import { useTopAssetAddresses } from '../../data/assets/topAsset'
import { useFetchedAssetDatas } from 'data/assets/assetData'

export default function Updater(): null {
  // updaters
  const updateAssetDatas = useUpdateAssetData()
  const addAssetKeys = useAddAssetKeys()

  // intitial data
  const allAssetData = useAllAssetData()
  const { loading, error, addresses } = useTopAssetAddresses()

  // add top pools on first load
  useEffect(() => {
    if (addresses && !error && !loading) {
      addAssetKeys(addresses)
    }
  }, [addAssetKeys, addresses, error, loading])

  // detect for which addresses we havent loaded asset data yet
  const unfetchedAssetAddresses = useMemo(() => {
    return Object.keys(allAssetData).reduce((accum: string[], key) => {
      const assetData = allAssetData[key]
      if (!assetData || !assetData.data || !assetData.lastUpdated) {
        accum.push(key)
      }
      return accum
    }, [])
  }, [allAssetData])

  // update unloaded pool entries with fetched data
  const { error: assetDataError, loading: assetDataLoading, data: assetDatas } = useFetchedAssetDatas(
    unfetchedAssetAddresses
  )
  useEffect(() => {
    if (assetDatas && !assetDataError && !assetDataLoading) {
      updateAssetDatas(Object.values(assetDatas))
    }
  }, [assetDataError, assetDataLoading, assetDatas, updateAssetDatas])

  return null
}
