import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { Currency, CurrencyAmount, Fraction, Percent, Token } from '@uniswap/sdk-core'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { ArbitrumNetworkInfo, BNBNetworkInfo, NetworkInfo } from 'constants/networks'
import JSBI from 'jsbi'
import { ROUTER_ADDRESS } from '../constants'
import { TokenAddressMap } from '../state/lists/hooks'

// @TODO: refactor
export enum PoolLabels {
  // BNB
  MAIN = 'MAIN',
  SIDE = 'SIDE',
  BNB = 'BNB',
  qWOM = 'qWOM',
  mWOM = 'mWOM',
  wmxWOM = 'wmxWOM',
  INNOVATION = 'INNOVATION',
  BNBx = 'BNBx',
  stkBNB = 'stkBNB',
  iUSD = 'iUSD',
  CUSD = 'CUSD',
  axlUSDC = 'axlUSDC',
  USDD = 'USDD',
  BOB = 'BOB',
  frxETH = 'frxETH',
  MIM = 'MIM',
  USDPlus = 'USD+',
  ankrBNB = 'ankrBNB',
  BNBy = 'BNBy',
  SMART_HAY = 'Smart HAY',
  WBETH = 'WBETH',
  ankrETH = 'ankrETH',

  // Arbitrum
  MAIN_ARB = 'MAIN',
  USDPlus_ARB = 'Overnight',
  MIM_ARB = 'MIM',
  BOB_ARB = 'BOB',
  mWOM_ARB = 'mWOM',
  qWOM_ARB = 'qWOM',
  wmxWOM_ARB = 'wmxWOM',
  frxETH_ARB = 'frxETH',
  FRAX_ARB = 'FRAX',
  jUSDC_ARB = 'jUSDC',
  ankrETH_ARB = 'ankrETH',
  wstETH_ARB = 'wstETH',
  fUSDC_ARB = 'fUSDC_ARB',
}

export enum IncubationTokenSymbols {
  MGP = 'MGP',
  QUO = 'QUO',
  WMX = 'WMX',
}

export interface IncubationToken {
  symbol: IncubationTokenSymbols
  name: string
  address: string
  quantity: number
}

export const IncubationTokens: IncubationToken[] = [
  {
    symbol: IncubationTokenSymbols.MGP,
    name: 'Magpie',
    address: '0xd06716e1ff2e492cc5034c2e81805562dd3b45fa',
    quantity: 30000000, // 3% of MGP Supply
  },
  {
    symbol: IncubationTokenSymbols.QUO,
    name: 'Quoll',
    address: '0x08b450e4a48c04cdf6db2bd4cf24057f7b9563ff',
    quantity: 40000000, // 4% of QUO supply
  },
  {
    symbol: IncubationTokenSymbols.WMX,
    name: 'Wombex',
    address: '0xa75d9ca2a0a1d547409d82e1b06618ec284a2ced',
    quantity: 3000000, // 3% of WMX supply
  },
]

export type IncubationTokenRevenue<T> = { [tokenAddress in IncubationTokenSymbols]: number }

export const POOL_DISPLAY_NAME: { [poolLabel: string]: string } = {
  // BNB Chain
  [PoolLabels.MAIN]: 'Main Pool',
  [PoolLabels.SIDE]: 'Smart HAY  Pool',
  [PoolLabels.BNB]: 'BNB Pool',
  [PoolLabels.qWOM]: 'qWOM Pool',
  [PoolLabels.mWOM]: 'mWOM Pool',
  [PoolLabels.wmxWOM]: 'wmxWOM Pool',
  [PoolLabels.INNOVATION]: 'FRAX Pool',
  [PoolLabels.BNBx]: 'BNBx Pool',
  [PoolLabels.stkBNB]: 'stkBNB Pool',
  [PoolLabels.iUSD]: 'iUSD Pool',
  [PoolLabels.CUSD]: 'CUSD Pool',
  [PoolLabels.axlUSDC]: 'axlUSDC Pool',
  [PoolLabels.USDD]: 'USDD Pool',
  [PoolLabels.BOB]: 'BOB Pool',
  [PoolLabels.frxETH]: 'frxETH Pool',
  [PoolLabels.MIM]: 'MIM Pool',
  [PoolLabels.USDPlus]: 'Stable Guild Pool',
  [PoolLabels.ankrBNB]: 'ankrBNB Pool',
  [PoolLabels.BNBy]: 'BNBy Pool',
  [PoolLabels.SMART_HAY]: 'Smart HAY Pool',
  [PoolLabels.WBETH]: 'wBETH Pool',
  [PoolLabels.ankrETH]: 'ankrETH Pool',
  // Arbitrum
  [PoolLabels.MAIN_ARB]: 'Main Pool',
  [PoolLabels.USDPlus_ARB]: 'Overnight Pool',
  [PoolLabels.MIM_ARB]: 'MIM Pool',
  [PoolLabels.BOB_ARB]: 'BOB Pool',
  [PoolLabels.mWOM_ARB]: 'mWOM Pool',
  [PoolLabels.qWOM_ARB]: 'qWOM Pool',
  [PoolLabels.wmxWOM_ARB]: 'wmxWOM Pool',
  [PoolLabels.frxETH_ARB]: 'frxETH Pool',
  [PoolLabels.FRAX_ARB]: 'FRAX Pool',
  [PoolLabels.jUSDC_ARB]: 'jUSDC Pool',
  [PoolLabels.ankrETH_ARB]: 'ankrETH Pool',
  [PoolLabels.wstETH_ARB]: 'wstETH Pool',
  [PoolLabels.fUSDC_ARB]: 'fUSDC Pool',
}

export const DEFAULT_SWAP_TOKEN: { [poolLabel: string]: string[] } = {
  // BNB Chain
  [PoolLabels.MAIN]: ['BUSD', 'USDC'],
  [PoolLabels.SIDE]: ['BUSD', 'HAY'],
  [PoolLabels.BNB]: ['WBNB', 'BNBx'],
  [PoolLabels.qWOM]: ['WOM', 'qWOM'],
  [PoolLabels.mWOM]: ['WOM', 'mWOM'],
  [PoolLabels.wmxWOM]: ['WOM', 'wmxWOM'],
  [PoolLabels.INNOVATION]: ['BUSD', 'TUSD'],
  [PoolLabels.BNBx]: ['WBNB', 'BNBx'],
  [PoolLabels.stkBNB]: ['WBNB', 'stkBNB'],
  [PoolLabels.iUSD]: ['iUSD', 'BUSD'],
  [PoolLabels.CUSD]: ['CUSD', 'HAY'],
  [PoolLabels.axlUSDC]: ['axlUSDC', 'BUSD'],
  [PoolLabels.USDD]: ['USDD', 'USDC'],
  [PoolLabels.BOB]: ['BOB', 'USDC'],
  [PoolLabels.frxETH]: ['frxETH', 'ETH'],
  [PoolLabels.MIM]: ['MIM', 'USDT'],
  [PoolLabels.USDPlus]: ['USDplus', 'USDC'],
  [PoolLabels.ankrBNB]: ['ankrBNB', 'WBNB'],
  [PoolLabels.BNBy]: ['BNBy', 'WBNB'],
  [PoolLabels.SMART_HAY]: ['HAY', 'USDC'],
  [PoolLabels.WBETH]: ['wBETH', 'ETH'],
  [PoolLabels.ankrETH]: ['ankrETH', 'ETH'],
  // Arbitrum
  [PoolLabels.MAIN_ARB]: ['USDT', 'USDC'],
  [PoolLabels.USDPlus_ARB]: ['USDplus', 'USDC'],
  [PoolLabels.MIM_ARB]: ['MIM', 'USDT'],
  [PoolLabels.BOB_ARB]: ['BOB', 'USDC'],
  [PoolLabels.mWOM_ARB]: ['mWOM', 'WOM'],
  [PoolLabels.qWOM_ARB]: ['qWOM', 'WOM'],
  [PoolLabels.wmxWOM_ARB]: ['wmxWOM', 'WOM'],
  [PoolLabels.frxETH_ARB]: ['frxETH', 'ETH'],
  [PoolLabels.FRAX_ARB]: ['MAI', 'FRAX'],
  [PoolLabels.jUSDC_ARB]: ['jUSDC', 'USDC'],
  [PoolLabels.ankrETH_ARB]: ['ankrETH', 'ETH'],
  [PoolLabels.wstETH_ARB]: ['wstETH', 'ETH'],
  [PoolLabels.fUSDC_ARB]: ['fUSDC', 'USDC'],
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getBscScanLink(data: string, type: 'transaction' | 'token' | 'address' | 'block'): string {
  if (data) {
    const prefix = 'https://bscscan.com'
    const stripped = data.split('-')[0]
    switch (type) {
      case 'transaction': {
        return `${prefix}/tx/${stripped}`
      }
      case 'token': {
        return `${prefix}/token/${stripped}`
      }
      case 'block': {
        return `${prefix}/block/${stripped}`
      }
      case 'address':
      default: {
        return `${prefix}/address/${stripped}`
      }
    }
  }
  return ''
}

export function getDappLink(poolLabel: string, tokenSymbol: string, type: 'SWAP' | 'DEPOSIT'): string {
  const prefix = 'https://app.wombat.exchange'
  if (tokenSymbol) {
    if (tokenSymbol === 'wmxWom') tokenSymbol = 'wmxWOM' // Temp fix for 'wmxWOM' token

    if (type === 'SWAP') {
      if (poolLabel === 'Unknown') return prefix
      const fromToken =
        tokenSymbol === DEFAULT_SWAP_TOKEN[poolLabel][0]
          ? DEFAULT_SWAP_TOKEN[poolLabel][1]
          : DEFAULT_SWAP_TOKEN[poolLabel][0]
      const path = `/swap?from=${fromToken}&to=${tokenSymbol}`
      return `${prefix}${path}`
    } else if (type === 'DEPOSIT') {
      const path = `/pool?pool=${poolLabel}&token=${tokenSymbol}&action=${type}`
      return `${prefix}${path}`
    } else {
      return prefix
    }
  }
  return prefix
}

export function getPoolLabel(address: string): string {
  switch (address) {
    // BNB Chain
    case '0x312bc7eaaf93f1c60dc5afc115fccde161055fb0':
      return PoolLabels.MAIN
    case '0x0029b7e8e9ed8001c868aa09c74a1ac6269d4183':
      return PoolLabels.BNB
    case '0x0520451b19ad0bb00ed35ef391086a692cfc74b2':
      return PoolLabels.SIDE
    case '0xeeb5a751e0f5231fc21c7415c4a4c6764f67ce2e':
      return PoolLabels.wmxWOM
    case '0x083640c5dbd5a8ddc30100fb09b45901e12f9f55':
      return PoolLabels.mWOM
    case '0x2c5464b9052319e3d76f8279031f04e4b7fd7955':
      return PoolLabels.qWOM
    case '0x48f6a8a0158031baf8ce3e45344518f1e69f2a14':
      return PoolLabels.INNOVATION
    case '0x8df1126de13bcfef999556899f469d64021adbae':
      return PoolLabels.BNBx
    case '0xb0219a90ef6a24a237bc038f7b7a6eac5e01edb0':
      return PoolLabels.stkBNB
    case '0x277e777f7687239b092c8845d4d2cd083a33c903':
      return PoolLabels.iUSD
    case '0x4dfa92842d05a790252a7f374323b9c86d7b7e12':
      return PoolLabels.CUSD
    case '0x8ad47d7ab304272322513ee63665906b64a49da2':
      return PoolLabels.axlUSDC
    case '0x05f727876d7c123b9bb41507251e2afd81ead09a':
      return PoolLabels.USDD
    case '0xea6cdd9e8819bbf7f8791e7d084d9f0a6afa7892':
      return PoolLabels.BOB
    case '0x2ea772346486972e7690219c190dadda40ac5da4':
      return PoolLabels.frxETH
    case '0xb8b1b72a9b9ba90e2539348fec1ad6b265f9f684':
      return PoolLabels.MIM
    case '0x9498563e47d7cfdfa22b818bb8112781036c201c':
      return PoolLabels.USDPlus
    case '0x6f1c689235580341562cdc3304e923cc8fad5bfa':
      return PoolLabels.ankrBNB
    case '0xbed9b758a681d73a95ab4c01309c63aa16297b80':
      return PoolLabels.BNBy
    case '0xa61dccc6c6e34c8fbf14527386ca35589e9b8c27':
      return PoolLabels.SMART_HAY
    case '0x1b507b97c89ede3e40d1b2ed92972197c6276d35':
      return PoolLabels.ankrETH
    case '0x8b892b6ea1d0e5b29b719d6bd6eb9354f1cde060':
      return PoolLabels.WBETH
    // Arbitrum
    case '0xc6bc781e20f9323012f6e422bdf552ff06ba6cd1':
      return PoolLabels.MAIN_ARB
    case '0xcf20fda54e37f3fb456930f02fb07fccf49e4849':
      return PoolLabels.USDPlus_ARB
    case '0x29eeb257a2a6ecde2984acedf80a1b687f18ec91':
      return PoolLabels.MIM_ARB
    case '0x917caf2b4d6040a9d67a5f8cefc4f89d1b214c1a':
      return PoolLabels.BOB_ARB
    case '0x90ecddec4e4116e30769a4e1ea52c319aca338b6':
      return PoolLabels.mWOM_ARB
    case '0xee9b42b40852a53c7361f527e638b485d49750cd':
      return PoolLabels.wmxWOM_ARB
    case '0x12fa5ab079cff564d599466d39715d35d90af978':
      return PoolLabels.qWOM_ARB
    case '0x20d7ee728900848752fa280fad51af40c47302f1':
      return PoolLabels.frxETH_ARB
    case '0x4a8686df475d4c44324210ffa3fc1dea705296e0':
      return PoolLabels.FRAX_ARB
    case '0xc7a6ba5f28993badb566007bd2e0cb253c431974':
      return PoolLabels.jUSDC_ARB
    case '0xb9bdfe449da096256fe7954ef61a18ee195db77b':
      return PoolLabels.ankrETH_ARB
    case '0xe14302040c0a1eb6fb5a4a79efa46d60029358d9':
      return PoolLabels.wstETH_ARB
    case '0x956454c7be9318863297309183c79b793d370401':
      return PoolLabels.fUSDC_ARB
    default:
      return 'Unknown'
  }
}

export function getEtherscanLink(
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block',
  networkVersion: NetworkInfo
): string {
  const prefix =
    networkVersion === BNBNetworkInfo
      ? 'https://bscscan.com'
      : networkVersion === ArbitrumNetworkInfo
      ? 'https://arbiscan.io'
      : ''
  data = data.split('-')[0]
  if (networkVersion === ArbitrumNetworkInfo) {
    switch (type) {
      case 'transaction': {
        return `${prefix}/tx/${data}`
      }
      case 'token': {
        return `${prefix}/address/${data}`
      }
      case 'block': {
        return 'https://arbiscan.io/'
      }
      case 'address':
      default: {
        return `${prefix}/address/${data}`
      }
    }
  }

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export const currentTimestamp = () => new Date().getTime()

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, startChars = 4, endChars = 4): string {
  if (!address) {
    return ''
  }
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, startChars + 2)}...${parsed.substring(42 - endChars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

const ONE = new Fraction(1, 1)
export function calculateSlippageAmount(value: CurrencyAmount<Currency>, slippage: Percent): [JSBI, JSBI] {
  if (slippage.lessThan(0) || slippage.greaterThan(ONE)) throw new Error('Unexpected slippage')
  return [value.multiply(ONE.subtract(slippage)).quotient, value.multiply(ONE.add(slippage)).quotient]
}
// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(tokenAddressMap: TokenAddressMap, token?: Token): boolean {
  return Boolean(token?.isToken && tokenAddressMap[token.chainId]?.[token.address])
}

export function feeTierPercent(fee: number): string {
  return (fee / 10000).toPrecision(1) + '%'
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const generateColors = () => {
  const currentColorHsl = {
    hue: 0,
    saturation: 40,
    lightness: 50,
  }

  return () => {
    const { hue, saturation, lightness } = currentColorHsl
    const color = `hsl(${hue},${saturation}%,${lightness}%)`
    currentColorHsl.hue += 30
    // 0 and 360 are the same color.
    if (currentColorHsl.hue > 330) {
      currentColorHsl.hue = 0
      currentColorHsl.saturation += 10
    }
    return color
  }
}
